import React from 'react';
import HelmetMeta from '../meta/HelmetMeta';
import { useTranslation } from 'react-i18next';

function Inbox() {
  const { t } = useTranslation();
  return (
    <HelmetMeta
      title={t('side_menu.notifications')}
      url={'/my_profile'}
      description={t('side_menu.notifications')}
    />
  );
}

export default Inbox;
