import firebase from '../../config/firebase';
import React, { useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import HelmetMeta from '../meta/HelmetMeta';
import { Card } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ListIcon from '@material-ui/icons/List';
import OrganizerDetails from '../../organizer-detail/OrganizerDetail';

const ACTIVITY_LIMIT = 500; // arbitrary

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  avatar: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

function OurOrganizers() {
  const [organisers, setOrganisers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const { t } = useTranslation();
  const [openParticipant, setOpenParticipant] = React.useState(false);
  const [selectedParticipant, setSelectedParticipant] = React.useState();
  const classes = useStyles();
  const handleDetailsClose = () => {
    setOpenParticipant(false);
  };
  const handleOrganizerInfos = (organizer) => {
    setSelectedParticipant(organizer);
    setOpenParticipant(true);
  };

  useEffect(() => {
    firebase
      .firestore()
      .collection('public-activities')
      .limit(ACTIVITY_LIMIT)
      .get()
      .then((querySnapshot) => {
        let organizerIds = new Set();
        querySnapshot.docs.forEach((doc) => {
          if (!organizerIds.has(doc.data().organizerID)) {
            organizerIds.add(doc.data().organizerID);
          }
        });

        firebase
          .firestore()
          .collection('public-users')
          .where(firebase.firestore.FieldPath.documentId(), 'in', [
            ...organizerIds,
          ])
          .get()
          .then((querySnapshot) => {
            let organizers = [];
            querySnapshot.docs.forEach((doc) =>
              organizers.push({
                id: doc.id,
                ...doc.data(),
              })
            );
            setOrganisers(organizers);
          });

        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <>
        <HelmetMeta
          title={t('side_menu.my_activities')}
          url={'/my_activities'}
          description={t('meta.desc_my_activities')}
        />
        <Backdrop open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  } else {
    return (
      <>
        {
          <Box
            mx="auto"
            mt={{ xs: 2, sm: 5, md: 5, lg: 5, xl: 5 }}
            mb={2}
            minwidth={380}
            maxWidth={700}
          >
            {organisers.map((organizer) => (
              <Box mt={3}>
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid
                        item
                        xs={4}
                        alignItems="center"
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Avatar
                          alt={organizer.name} //initials will be displayed if no pic
                          src={organizer.photoURL}
                          className={classes.avatar}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                          color="primary"
                          variant="h5"
                          component="h2"
                          align="left"
                        >
                          {organizer.name}
                        </Typography>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          {organizer.city}
                        </Typography>
                        <Typography variant="body2" component="p">
                          {organizer.aboutMe}
                        </Typography>
                        <Typography variant="body2" component="p" mt={2}>
                          <Box mt={2}>
                            <Button
                              startIcon={<ListIcon />}
                              size={'small'}
                              onClick={() => handleOrganizerInfos(organizer)}
                              variant="contained"
                              color="primary"
                            >
                              {t('organizers.their_activities')}
                            </Button>
                          </Box>
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            ))}
            <OrganizerDetails
              open={openParticipant}
              handleClose={handleDetailsClose}
              organizer={selectedParticipant}
            />
          </Box>
        }
      </>
    );
  }
}

export default OurOrganizers;
