import React from 'react';
import MyProfileForm from './MyProfileForm';
import { useSelector } from 'react-redux';
import HelmetMeta from '../meta/HelmetMeta';
import { useTranslation } from 'react-i18next';

function MyProfile() {
  const profile = useSelector((state) => state.firebase.profile);
  const auth = useSelector((state) => state.firebase.auth);
  const { t } = useTranslation();
  return (
    <>
      <HelmetMeta
        title={t('side_menu.my_profile')}
        url={'/my_profile'}
        description={t('side_menu.my_profile')}
      />
      {profile.isLoaded && !profile.isEmpty && (
        <MyProfileForm profile={profile} auth={auth} />
      )}
    </>
  );
}

export default MyProfile;
