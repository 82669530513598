import React, { Fragment, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import RequestJoinActivityDialog from './RequestJoinActivityDialog';
import { useSelector } from 'react-redux';
import firebase from '../../config/firebase';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

function RequestJoinActivityButton({ activityID }) {
  const [open, setOpen] = React.useState(false);
  const [requestSent, setRequestSent] = React.useState(false);
  const [joinRequest, setJoinRequest] = React.useState();
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const { t } = useTranslation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleRequestSent = () => setRequestSent(true);

  useEffect(() => {
    if (auth.uid) {
      firebase
        .firestore()
        .collection('activities')
        .doc(activityID)
        .collection('requests')
        .doc(auth.uid)
        .get()
        .then(
          (joinRequest) => joinRequest.exists && setJoinRequest(joinRequest.data())
        );
    }
  }, [activityID, auth.uid]);

  return (
    <Fragment>
      { (requestSent || (joinRequest && joinRequest.status === 'pending')) && (
        <Box textAlign="center">
          <Button
            color="default"
            variant="contained"
            size="medium"
            disabled={true}
          >
            { t('activity_detail.request_sent') }
          </Button>
        </Box>
      )}
      {!joinRequest && !requestSent && (
        <Button
          color="primary"
          variant="contained"
          size="medium"
          onClick={handleOpen}
        >
          { t('activity_detail.request_join_btn') }
        </Button>
      )}
      <RequestJoinActivityDialog
        open={open}
        handleClose={handleClose}
        handleRequestSent={handleRequestSent}
        activityID={activityID}
        auth={auth}
        profile={profile}
      />
    </Fragment>
  );
}

export default RequestJoinActivityButton;
