import React, { Fragment, useEffect, useState } from 'react';
import { Chip, Typography } from '@material-ui/core';
import firebase from '../../config/firebase';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import Box from '@material-ui/core/Box';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { Link, useHistory } from 'react-router-dom';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import dayjs from 'dayjs';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import PeopleRoundedIcon from '@material-ui/icons/PeopleRounded';
import CheckIcon from '@material-ui/icons/Check';
import RequestJoinActivityButton from '../request-join-activity/RequestJoinActivityButton';
import ApproveRequestsButton from '../approve-requests/ApproveRequestsButton';
import AuthDialogBtn from '../auth-dialog/AuthDialogBtn';
import ReportIssueButton from '../report-issue/ReportIssueButton';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import TextField from '@material-ui/core/TextField';
import { markPublicActivityAsDeleted, recursiveDeleteActivity } from '../../common/firestoreService';
import ActivityChatButton from '../activity-chat/ActivityChatButton';
import HelmetMeta from '../meta/HelmetMeta';
import { useTranslation } from 'react-i18next';
import ActivityDialog from '../activity-dialog/ActivityDialog';
import { baseURL, translatedRoutes } from '../../common/routes';
import ShareActivityMenuButton from '../share-activity/ShareActivityMenuButton';
import ShareActivityButton from '../share-activity/ShareActivityButton';
import Hidden from '@material-ui/core/Hidden';
import ParticipantDetails from '../../participant-detail/ParticipantDetails';
import ApproveRequestsDialog from '../approve-requests/ApproveRequestsDialog';
import Map from '../map/Map';
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'relative',
    width: 300,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    outline: 0, // remove blue outline
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  fbButton: {
    background: '#3C5898',
    color: 'white',
    height: 45,
    width: 200,
  },
  whButton: {
    background: '#25D366',
    color: 'white',
    height: 45,
    width: 200,
  },
  mailButton: {
    height: 45,
    width: 200,
  },
  delActivity: {
    background: '#FA4353',
    color: 'white',
    height: 45,
    width: 200,
  },
}));

function ActivityDetail({ props, auth }) {
  const [loading, setLoading] = React.useState(true);
  const [activity, setActivity] = React.useState();
  const [isOrganizer, setIsOrganizer] = React.useState(false);
  const [isAcceptedUser, setIsAcceptedUser] = React.useState(false);
  const [participantCount, setParticipantCount] = React.useState(0);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = React.useState(false);
  const [openMoreModal, setOpenMoreModal] = React.useState(false);
  const [openDelConf, setOpenDelConf] = React.useState(false);
  const [openParticipant, setOpenParticipant] = React.useState(false);
  const [participants, setParticipants] = React.useState([]);
  const [deleteText, setDeleteText] = React.useState('');
  const [selectedParticipant, setSelectedParticipant] = React.useState();
  const [openApprovedPart, setOpenApprovedPart] = useState(false);
  const [updatedFormActivity, updateFormActivity] = useState();
  const redirID = props.match.params.id;
  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const processSnapshot = (activity, id) => {
    activity.startTime = activity.startDate.seconds * 1000;
    activity.endTime = activity.endDate.seconds * 1000;
    activity.city = activity.location.city;
    activity.id = id;
    setActivity(activity);
    setLoading(false);
  };
  const increaseParticipantCount = () =>
    setParticipantCount(participantCount + 1);
  const decreaseParticipantCount = () =>
    setParticipantCount(participantCount - 1);

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
  };
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };
  const handleOpenMoreModal = () => {
    setOpenMoreModal(true);
  };
  const handleCloseMoreModal = () => {
    setOpenMoreModal(false);
  };
  const handleOpenDelConfModal = () => {
    setOpenDelConf(true);
    setOpenMoreModal(false);
  };
  const handleCloseDelConfModal = () => {
    setOpenDelConf(false);
  };

  const handledelete = async () => {
    if (['oui', 'yes'].includes(deleteText?.toLowerCase())) {
      await markPublicActivityAsDeleted(activity.id)
      recursiveDeleteActivity(activity.id, activity.organizerID);
      setOpenDelConf(false);
      history.push(translatedRoutes[i18n.language].myActivities);
    }
  };
  const handleOpenDuplicateModal = () => {
    setOpenDuplicateModal(true);
  };
  const handleCloseDuplicateModal = () => {
    setOpenDuplicateModal(false);
    setOpenMoreModal(false);
  };
  const handleOrganizerInfos = () => {
    const organizer = {
      photoURL: activity.organizerPhotoURL,
      publicName: activity.organizerName,
      aboutMe: activity.aboutOrganizer,
    };
    setSelectedParticipant(organizer);
    setOpenParticipant(true);
  };
  const handleDetailsClick = (request) => {
    setSelectedParticipant(request);
    setOpenParticipant(true);
  };
  const handleDetailsClose = () => {
    setOpenParticipant(false);
  };
  const handleCloseApprovedPart = () => {
    setOpenApprovedPart(false);
  };

  useEffect(() => {
    firebase
      .firestore()
      .collection('activities')
      .doc(redirID)
      .get()
      .then(function (querySnapshot) {
        if (querySnapshot.data() !== undefined) {
          processSnapshot(querySnapshot.data(), querySnapshot.id);
          if (querySnapshot.data().organizerID === auth?.uid) {
            setIsOrganizer(true);
            setIsAcceptedUser(false);
          } else {
            setIsOrganizer(false);
            setIsAcceptedUser(true);
          }
        }
      })
      .catch(function (_error) {
        firebase
          .firestore()
          .collection('public-activities')
          .doc(redirID)
          .get()
          .then(function (querySnapshot) {
            if (querySnapshot.data() !== undefined) {
              setIsOrganizer(false);
              setIsAcceptedUser(false);
              processSnapshot(querySnapshot.data(), querySnapshot.id);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    firebase
      .firestore()
      .collection('activities')
      .doc(redirID)
      .collection('participant-count')
      .doc(redirID)
      .get()
      .then((doc) => {
        setParticipantCount(doc.exists ? doc.data().total : 1);
      });
    firebase
      .firestore()
      .collection('activities')
      .doc(redirID)
      .collection('participants')
      .get()
      .then(function (querySnapshot) {
        let newParticipants = [];
        querySnapshot.docs.forEach(function (doc) {
          const part = doc.data();
          newParticipants.push({
            ...part,
            id: doc.id,
          });
        });
        setParticipants(newParticipants);
      });
    // use Objet.values to prevent infinite loop, only trigger hook when activity has been updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedFormActivity, auth, redirID]);

  if (loading) {
    return (
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else {
    const startTime = dayjs(activity.startTime);
    const endTime = dayjs(activity.endTime);
    const activityUrl =
      baseURL +
      translatedRoutes[i18n.language].activityDetailWithoutId +
      '/' +
      activity.id;

    return (
      <div>
        <HelmetMeta
          title={activity.title}
          url={activityUrl}
          description={activity.publicDescription}
          image={activity.activityImageURL}
        />
        <Box
          maxWidth={800}
          mt={{ xs: 0, sm: 5, md: 5, lg: 5, xl: 5 }}
          px={{ sm: '2%', xs: 0 }}
          mx="auto"
        >
          <AppBar
            position="static"
            color="transparent"
            borderRadius={12}
            elevation={1}
          >
            <Toolbar>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                wrap={{ sm: 'nowrap', xs: 'wrap' }}
              >
                <Hidden xsDown>
                  <IconButton
                    align="left"
                    color="inherit"
                    aria-label="menu"
                    component={Link}
                    to="/"
                  >
                    <ArrowBackIosRoundedIcon color="secondary" />
                  </IconButton>
                </Hidden>
                <Typography
                  color="primary"
                  variant="h5"
                  component="h2"
                  align="left"
                >
                  {activity.title}
                </Typography>
                <Box>
                  {isOrganizer && (
                    <IconButton
                      align="right"
                      color="inherit"
                      aria-label="menu"
                      onClick={handleOpenEditModal}
                    >
                      <EditRoundedIcon color="secondary" />
                    </IconButton>
                  )}
                  <ShareActivityMenuButton activity={activity} />
                  {isOrganizer && (
                    <IconButton
                      align="right"
                      color="inherit"
                      aria-label="menu"
                      onClick={handleOpenMoreModal}
                    >
                      <MoreVertIcon color="secondary" />
                    </IconButton>
                  )}
                </Box>
              </Grid>
            </Toolbar>
          </AppBar>
          <Paper>
            <CardMedia
              component="img" //will rely in object-fit for image centering
              height="300"
              image={activity.activityImageURL}
            />
            <Box
              mt="2%"
              mx={{ xs: 2, sm: '10%', md: '10%', lg: '10%', xl: '10%' }}
            >
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Grid container direction="column">
                    <Box display="flex" alignItems="center">
                      <TodayRoundedIcon color="secondary" />
                      <Box mx={0.4}>
                        <Typography
                          noWrap
                          color="secondary"
                          variant="body2"
                          component="p"
                          align="left"
                        >
                          {startTime.format('DD/MM/YY')}
                        </Typography>
                      </Box>
                    </Box>
                    {participants.length === 0 && ( // doing this as it takes the cloud function time to create participant array with organizer
                      <Box display="flex" alignItems="center">
                        <PeopleRoundedIcon color="secondary" />
                        <Box ml={0.4}>
                          <Typography
                            noWrap
                            color="secondary"
                            variant="body2"
                            component="p"
                            align="left"
                          >
                            {participantCount === 1 && (
                              <span>1 {t('activity_detail.participant')}</span>
                            )}
                            {participantCount !== 1 && (
                              <span>
                                {participantCount}{' '}
                                {t('activity_detail.participants')}
                              </span>
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    {participants.length > 0 && (
                      <Box pt={0.3}>
                        <Chip
                          avatar={<PeopleRoundedIcon color="secondary" />}
                          size={'small'}
                          label={
                            <Box>
                              <Typography
                                noWrap
                                color="secondary"
                                variant="body2"
                                component="p"
                                align="left"
                              >
                                {participantCount === 1 && (
                                  <span>
                                    1 {t('activity_detail.participant')}
                                  </span>
                                )}
                                {participantCount !== 1 && (
                                  <span>
                                    {participantCount}{' '}
                                    {t('activity_detail.participants')}
                                  </span>
                                )}
                              </Typography>
                            </Box>
                          }
                          onClick={() => setOpenApprovedPart(true)}
                        />
                      </Box>
                    )}
                    <Box display="flex" alignItems="center">
                      <LocationOnIcon color="secondary" />
                      <Box ml={0.4}>
                        <Typography
                          noWrap
                          color="secondary"
                          variant="body2"
                          component="p"
                          align="left"
                        >
                          {activity.location.city !== null
                            ? activity.location.city
                            : 'not found'}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container direction="column">
                    <Box display="flex" alignItems="center">
                      <ScheduleRoundedIcon color="secondary" />
                      <Box ml={0.4}>
                        <Typography
                          noWrap
                          color="secondary"
                          variant="body2"
                          component="p"
                          align="left"
                        >
                          {startTime.format('HH:mm')} - {endTime.format('HH:mm')}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      pt={0.3}
                      display="flex"
                      alignItems="center"
                      justify-content="space-between"
                    >
                      <Chip
                        size={'small'}
                        avatar={
                          <Avatar
                            alt={activity.organizerName} //initials will be displayed if no pic
                            src={activity.organizerPhotoURL}
                            className={classes.small}
                          />
                        }
                        label={activity.organizerName}
                        onClick={handleOrganizerInfos}
                      />
                    </Box>
                    {isOrganizer && (
                      <Box display="flex" alignItems="center">
                        <CheckIcon color="primary" />
                        <Box ml={0.4}>
                          <Typography
                            noWrap
                            color="primary"
                            variant="body2"
                            component="p"
                            align="left"
                          >
                            {t('activity_detail.youre_organizer')}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    {isAcceptedUser && (
                      <Box display="flex" alignItems="center">
                        <CheckIcon color="primary" />
                        <Box ml={0.4}>
                          <Typography
                            noWrap
                            color="primary"
                            variant="body2"
                            component="p"
                            align="left"
                          >
                            {t('activity_detail.youre_going')}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            {activity && (isAcceptedUser || isOrganizer) && (
              <>
                <Box display="flex" justifyContent="center" mt={3}>
                  <ActivityChatButton activity={activity} />
                </Box>
                <Box display="flex" justifyContent="center" mt={1}>
                  <ShareActivityButton activity={activity} />
                </Box>
              </>
            )}
            <Box display="flex" justifyContent="center" mt={1}>
              {auth.isLoaded && auth.isEmpty && (
                <AuthDialogBtn
                  buttonText={t('activity_detail.request_join_btn')}
                />
              )}
              {isOrganizer && (
                <ApproveRequestsButton
                  isOrganizer={isOrganizer}
                  participants={participants}
                  activityID={activity.id}
                  increaseParticipantCount={increaseParticipantCount}
                  decreaseParticipantCount={decreaseParticipantCount}
                  handleDetailsClick={handleDetailsClick}
                  setParticipants={setParticipants}
                />
              )}
              {auth.isLoaded &&
                !auth.isEmpty &&
                auth?.uid !== activity.organizerID && (
                  <RequestJoinActivityButton activityID={activity.id} />
                )}
            </Box>
            <Box mt={3} px="2%">
              <Typography
                noWrap
                align="left"
                color="primary"
                variant="h5"
                component="h2"
              >
                {t('activity_detail.public_info')}
              </Typography>
            </Box>
            <Box mt={2} px="2%">
              <Typography
                color="secondary"
                variant="body2"
                component="p"
                align="left"
              >
                {activity.publicDescription || activity.description}
              </Typography>
            </Box>
            {(isOrganizer || isAcceptedUser) && (
              <Fragment>
                <Box mt={3} px="2%">
                  <Typography
                    noWrap
                    align="left"
                    color="primary"
                    variant="h5"
                    component="h2"
                  >
                    {t('activity_detail.private_info')}
                  </Typography>
                </Box>
                <Box mt={2} px="2%">
                  <Typography
                    color="secondary"
                    variant="body2"
                    component="p"
                    align="left"
                  >
                    {activity.privateDescription}
                  </Typography>
                </Box>
                <Box mt={3} px="2%">
                  <Typography
                    noWrap
                    align="left"
                    color="primary"
                    variant="h5"
                    component="h2"
                  >
                    {t('activity_detail.location')}
                  </Typography>
                </Box>
                <Box px="2%" mt={3}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Map
                      lat={activity.coordinates.latitude}
                      lng={activity.coordinates.longitude}
                    />
                    <Box ml={3} mt={3}>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        color="secondary"
                      >
                        { [activity.location.street, activity.location.city, activity.location.country].filter(e => e).join(', ') }
                      </Typography>
                    </Box>
                  </Grid>
                </Box>
              </Fragment>
            )}

            { activity.aboutOrganizer.length > 0 && <>
              <Box mt={2} px="2%">
                <Typography
                  color="primary"
                  variant="h5"
                  component="h2"
                  align="left"
                  noWrap
                >
                  {t('activity_detail.about')} {activity.organizerName}
                </Typography>
              </Box>
              <Box mt={2} pb={2} px="2%">
                <Typography
                  color="secondary"
                  variant="body2"
                  component="p"
                  align="left"
                >
                  {activity.aboutOrganizer}
                </Typography>
              </Box>
            </> }
            <Box display="flex" justifyContent="center" mt={3} pb={1}>
              <Button
                color="primary"
                variant="contained"
                size="medium"
                component={Link}
                to="/"
              >
                {t('activity_detail.activity_list_btn')}
              </Button>
            </Box>
            {/*{fullAccess && (*/}
            {/*  <Fragment>*/}
            {/*    <Box display="flex" justifyContent="center" mt={1} mb={5}>*/}
            {/*      <Button color="secondary" variant="contained" size="medium">*/}
            {/*        {t('activity_detail.leave_btn')}*/}
            {/*      </Button>*/}
            {/*    </Box>*/}
            {/*  </Fragment>*/}
            {/*)}*/}
            <Box display="flex" justifyContent="center" pb={1}>
              <ReportIssueButton />
            </Box>
          </Paper>
          <ActivityDialog
            open={openEditModal}
            handleClose={handleCloseEditModal}
            activity={activity}
            updateActivity={updateFormActivity}
          />
          <Dialog
            maxWidth="xs"
            open={openMoreModal}
            close={handleCloseMoreModal}
            onBackdropClick={handleCloseMoreModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              className={classes.modal}
            >
              <div className={classes.paper}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box mt={2} px="2%">
                    <Typography
                      color="secondary"
                      variant="h5"
                      component="h2"
                      align="left"
                      noWrap
                    >
                      {t('common.actions')}
                    </Typography>
                  </Box>
                  <Box mt={3}>
                    <Button
                      variant="contained"
                      className={classes.whButton}
                      startIcon={<FileCopyRoundedIcon />}
                      onClick={handleOpenDuplicateModal}
                    >
                      {t('activity_detail.duplicate_btn')}
                    </Button>
                  </Box>
                  <Box mt={2}>
                    <Button
                      variant="contained"
                      className={classes.delActivity}
                      startIcon={<CloseRoundedIcon />}
                      onClick={handleOpenDelConfModal}
                    >
                      {t('activity_detail.delete_btn')}
                    </Button>
                  </Box>
                </Grid>
              </div>
            </Box>
          </Dialog>
          <Dialog
            open={openDelConf}
            close={handleCloseDelConfModal}
            onBackdropClick={handleCloseDelConfModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              className={classes.modal}
            >
              <div className={classes.paper}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box mt={2} px="2%">
                    <Typography
                      color="secondary"
                      variant="h5"
                      component="h2"
                      align="left"
                      noWrap
                    >
                      {t('common.are_you_sure')}
                    </Typography>
                  </Box>
                  <Box mt={2} px="2%">
                    <TextField
                      variant="outlined"
                      fullWidth
                      onChange={(event) => setDeleteText(event.target.value)}
                      label={t('activity_detail.yes_to_delete')}
                      name="title"
                    />
                  </Box>
                  <Box mt={2}>
                    <Button
                      disabled={!['oui', 'yes'].includes(deleteText?.toLowerCase())}
                      variant="contained"
                      className={classes.delActivity}
                      startIcon={<CloseRoundedIcon />}
                      onClick={handledelete}
                    >
                      {t('activity_detail.delete_btn')}
                    </Button>
                  </Box>
                </Grid>
              </div>
            </Box>
          </Dialog>
          <ParticipantDetails
            open={openParticipant}
            handleClose={handleDetailsClose}
            participant={selectedParticipant}
          />
          <ActivityDialog
            open={openDuplicateModal}
            handleClose={handleCloseDuplicateModal}
            activity={activity}
            duplicate={true}
          />
          <ApproveRequestsDialog
            isOrganizer={false}
            open={openApprovedPart}
            handleClose={handleCloseApprovedPart}
            joinRequests={participants}
            decreaseParticipantCount={decreaseParticipantCount}
            increaseParticipantCount={increaseParticipantCount}
            handleDetailsClick={handleDetailsClick}
          />
        </Box>
      </div>
    );
  }
}

export default ActivityDetail;
