import React, { useEffect } from 'react';
import { Dialog, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import firebase from '../config/firebase';
import UpcomingActivityCard from './UpcomingActivityCard';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(25),
    height: theme.spacing(25),
  },
}));

function OrganizerDetails({ handleClose, open, organizer }) {
  const classes = useStyles();
  const [singleActivities, setSingleActivities] = React.useState([]);
  const [recurringActivities, setRecurringActivities] = React.useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (!organizer) {
      return;
    }

    firebase
      .firestore()
      .collection('public-activities')
      .where('organizerID', '==', organizer.id)
      .get()
      .then((querySnapshot) => {
        const activities = [];
        querySnapshot.docs.forEach((doc) => {
          if (doc.data().recurringActivityID === undefined) {
            activities.push({
              ...doc.data(),
              id: doc.id,
            });
          }
        });

        setSingleActivities(activities);
      });

    firebase
      .firestore()
      .collection('public-recurring-activities')
      .where('organizerID', '==', organizer.id)
      .get()
      .then((querySnapshot) => {
        const activities = [];
        querySnapshot.docs.forEach((doc) => {
          activities.push({
            ...doc.data(),
            id: doc.id,
          });
        });

        setRecurringActivities(activities);
      });
  }, [organizer]);
  return (
    <Dialog maxWidth="xs" open={open} fullWidth={true} onClose={handleClose}>
      {organizer && (
        <>
          <Box mx={4} my={4}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Avatar src={organizer.photoURL} className={classes.avatar} />
              <Box mt={2} px="2%">
                <Typography
                  color="primary"
                  variant="h5"
                  component="h2"
                  align="center"
                  noWrap
                >
                  {organizer.name}
                </Typography>
              </Box>
              <Box mt={2} pb={2} px="2%">
                <Typography
                  color="secondary"
                  variant="body2"
                  component="p"
                  align="left"
                  NoWrap
                >
                  {organizer.aboutMe}
                </Typography>
              </Box>
            </Grid>

            <Box mt={2} mb={2}>
              <Typography
                noWrap
                color="secondary"
                variant="h5"
                component="h2"
                align="center"
              >
                {t('organizers.recurring_activities')}
              </Typography>
            </Box>
            {recurringActivities.map((activity) => {
              return (
                <UpcomingActivityCard
                  activity={activity}
                ></UpcomingActivityCard>
              );
            })}

            <Box mt={2} mb={2}>
              <Typography
                noWrap
                color="secondary"
                variant="h5"
                component="h2"
                align="center"
              >
                {t('organizers.single_activities')}
              </Typography>
            </Box>
            {singleActivities.map((activity) => {
              return (
                <UpcomingActivityCard
                  activity={activity}
                ></UpcomingActivityCard>
              );
            })}
          </Box>
        </>
      )}
    </Dialog>
  );
}

export default OrganizerDetails;
