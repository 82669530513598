import React, { useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import firebase from '../../config/firebase';
import MyActivitiesList from './MyActivitiesList';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OrganizeActivityButton from './OrganizeActivityButton';
import HelmetMeta from '../meta/HelmetMeta';

function MyActivities({ userID }) {
  const [activities, setActivities] = React.useState([]);
  const [recurringActivities, setRecurringActivities] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    firebase
      .firestore()
      .collection('recurring-activities')
      .where('organizerID', '==', userID)
      // .where('deleted', '!=', true)
      .get()
      .then((querySnapshot) => {
        const recurringActivities = [];
        querySnapshot.docs.forEach((doc) => {
          const activity = {
            ...doc.data(),
            id: doc.id,
          };

          recurringActivities.push(activity);
        });
        setRecurringActivities(recurringActivities);
      });

    firebase
      .firestore()
      .collectionGroup('requests')
      .where('userID', '==', userID)
      .get()
      .then((querySnapshot) => {
        const requests = [];
        const activityIDs = [];
        const publicActivities = [];
        const activityQueryPromises = [];

        querySnapshot.docs.forEach((doc) => {
          requests.push(doc.data());
          activityIDs.push(doc.data().activityID);
        });

        while (activityIDs.length > 0) {
          // batching activity query per 10
          // as Firebase IN queries support up to 10 comparison values
          const batchIDs = activityIDs.splice(0, 10);

          activityQueryPromises.push(
            firebase
              .firestore()
              .collection('public-activities')
              .where(firebase.firestore.FieldPath.documentId(), 'in', batchIDs)
              .get()
              .then((querySnapshot) => {
                querySnapshot.docs.forEach((doc) => {
                  const request = requests.find(
                    (request) => request.activityID === doc.id
                  );

                  if (doc.data().deleted) {
                    return;
                  }

                  const activity = {
                    ...doc.data(),
                    id: doc.id,
                    status: request.status,
                    startTime: doc.data().startDate.seconds * 1000,
                    endTime: doc.data().endDate.seconds * 1000,
                  };

                  publicActivities.push(activity);
                });
              })
          );
        }

        Promise.all(activityQueryPromises).then(() => {
          setActivities(
            publicActivities.sort((a, b) => a.startTime - b.startTime)
          );
          setLoading(false);
        });
      });
  }, [userID]);

  if (loading) {
    return (
      <>
        <HelmetMeta
          title={t('side_menu.my_activities')}
          url={'/my_activities'}
          description={t('meta.desc_my_activities')}
        />
        <Backdrop open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  } else {
    return (
      <>
        {!activities.find(
          (activity) =>
            ['approved', 'organizer'].includes(activity.status) &&
            activity.endTime > Date.now()
        ) && (
          <Box mt={{ xs: 0, sm: 5, md: 5, lg: 5, xl: 5 }} mb={2}>
            <Typography variant="h4" align="center">
              {t('my_activities.no_activities_yet')}
            </Typography>
            <Box pt={4} textAlign={'center'}>
              <Button
                component={NavLink}
                to="/"
                variant="outlined"
                color="primary"
              >
                <Typography color="primary">
                  {t('my_activities.find_activities')}
                </Typography>
              </Button>
              <Box mx={1}>
                <Typography
                  variant="subtitle1"
                  display="inline"
                  color="secondary"
                >
                  {t('common.or')}
                </Typography>
              </Box>
              <OrganizeActivityButton />
            </Box>
          </Box>
        )}
        <Box mt={{ xs: -6, sm: 5, md: 5, lg: 5, xl: 5 }}>
          {recurringActivities.length > 0 && (
            <MyActivitiesList
              title={t('my_activities.recurring_activities')}
              activities={recurringActivities}
            />
          )}

          {activities.find(
            (activity) =>
              activity.status === 'approved' && activity.endTime > Date.now()
          ) && (
            <MyActivitiesList
              title={t('my_activities.upcoming_activities')}
              activities={activities.filter(
                (activity) =>
                  activity.status === 'approved' &&
                  activity.endTime > Date.now()
              )}
            />
          )}
          {activities.find(
            (activity) =>
              activity.status === 'organizer' && activity.endTime > Date.now()
          ) && (
            <MyActivitiesList
              title={t('my_activities.activities_organized_by_you')}
              activities={activities.filter(
                (activity) =>
                  activity.status === 'organizer' &&
                  activity.endTime > Date.now()
              )}
            />
          )}
          {activities.find(
            (activity) =>
              (activity.status === 'approved' ||
                activity.status === 'organizer') &&
              activity.endTime < Date.now()
          ) && (
            <MyActivitiesList
              title={t('my_activities.past_activities')}
              activities={activities.filter(
                (activity) =>
                  (activity.status === 'approved' ||
                    activity.status === 'organizer') &&
                  activity.endTime < Date.now()
              )}
            />
          )}

          {activities.find(
            (activity) =>
              activity.status === 'pending' && activity.endTime > Date.now()
          ) && (
            <MyActivitiesList
              title={t('my_activities.waiting_for_approval')}
              activities={activities.filter(
                (activity) =>
                  activity.status === 'pending' && activity.endTime > Date.now()
              )}
            />
          )}
        </Box>
      </>
    );
  }
}

export default MyActivities;
