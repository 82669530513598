import React, { useState } from 'react';
import AutoRotatingCarousel from 'material-auto-rotating-carousel/lib/AutoRotatingCarousel';
import Slide from 'material-auto-rotating-carousel/lib/Slide';
import { useMediaQuery, useTheme } from '@material-ui/core';
import onyv from '../layout/onyv_logo.png';
import cling from '../layout/cling_large.jpg';
import chat from '../layout/chat.png';
import search from '../layout/search.png';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';

const moreThanOneMonthAgo = (date) => {
  const oneMonthInMs = 2628000000;
  const timeDifferenceInMs = Date.now() - date;

  return timeDifferenceInMs > oneMonthInMs;
};

const useStyles = makeStyles((theme) => ({
  title: {
    whiteSpace: 'normal',
  },
}));

function IntroCarousel() {
  const classes = useStyles();
  const introTimestamp = localStorage.getItem('introTimestamp');
  const show =
    !introTimestamp || moreThanOneMonthAgo(Date.parse(introTimestamp));
  const [open, setOpen] = useState(show);
  const theme = useTheme();
  const { t } = useTranslation();
  const onMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const onClose = () => {
    localStorage.setItem('introTimestamp', new Date().toISOString());
    setOpen(false);
  };
  const onyvColor = '#25D366';

  return (
    <AutoRotatingCarousel
      label={t('intro.continue_btn')}
      ButtonProps={{ color: 'primary' }}
      open={open}
      onClose={onClose}
      onStart={onClose}
      autoplay={true}
      mobile={onMobile}
      style={{ position: 'absolute' }}
    >
      <Slide
        media={<img alt="onyv" src={onyv} width={'256px'} height={'auto'} />}
        mediaBackgroundStyle={{ backgroundColor: 'white' }}
        style={{ backgroundColor: onyvColor }}
        title={t('intro.onyv_title')}
        classes={{ title: classes.title }}
        subtitle={t('intro.onyv_subtitle')}
      />
      <Slide
        media={
          <img alt="cherche" src={search} width={'256px'} height={'auto'} />
        }
        mediaBackgroundStyle={{ backgroundColor: onyvColor }}
        style={{ backgroundColor: onyvColor }}
        title={t('intro.search_title')}
        classes={{ title: classes.title }}
        subtitle={t('intro.search_subtitle')}
      />
      <Slide
        media={<img alt="chat" src={chat} width={'256px'} height={'auto'} />}
        mediaBackgroundStyle={{ backgroundColor: onyvColor }}
        style={{ backgroundColor: onyvColor }}
        title={t('intro.chat_title')}
        classes={{ title: classes.title }}
        subtitle={t('intro.chat_subtitle')}
      />
      <Slide
        media={
          <img
            alt="amusez"
            src={cling}
            width={'100%'}
            height={'100%'}
            style={{ objectFit: 'cover' }}
          />
        }
        mediaBackgroundStyle={{ backgroundColor: onyvColor }}
        style={{ backgroundColor: onyvColor }}
        classes={{ title: classes.title }}
        title={t('intro.have_fun_title')}
      />
    </AutoRotatingCarousel>
  );
}

export default IntroCarousel;
