import React, { useEffect, useState } from 'react';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  media: {
    width: 120,
    height: 80
  }
}));

const imageList = [
  'https://firebasestorage.googleapis.com/v0/b/onyv-production.appspot.com/o/activity-photos%2Fandreas-fischinger-xosBoKRT0qE-unsplash.jpg?alt=media&token=e374718c-f767-4322-a777-422969c63e52',
  'https://firebasestorage.googleapis.com/v0/b/onyv-production.appspot.com/o/activity-photos%2Fangela-pham-vo7GGTh6sXM-unsplash.jpg?alt=media&token=33e75a2b-8b7b-482e-87fb-ae4ada9d7a80',
  'https://firebasestorage.googleapis.com/v0/b/onyv-production.appspot.com/o/activity-photos%2Fbaptiste-gousset-mzpAUFtykbs-unsplash.jpg?alt=media&token=6fd2f2c5-1638-4ea3-89c2-fe886d567a06',
  'https://firebasestorage.googleapis.com/v0/b/onyv-production.appspot.com/o/activity-photos%2Fbrett-jordan-m1PgHI83ScQ-unsplash.jpg?alt=media&token=71fe1261-0481-4466-9dd9-50d088d1deb7',
  'https://firebasestorage.googleapis.com/v0/b/onyv-production.appspot.com/o/activity-photos%2Fcolton-miller-GAaBrqz7QUI-unsplash.jpg?alt=media&token=2ffb891b-9005-45d3-b2e8-85132113a020',
  'https://firebasestorage.googleapis.com/v0/b/onyv-production.appspot.com/o/activity-photos%2Felevate-snnhGYNqm44-unsplash.jpg?alt=media&token=f48e199c-dce1-4da3-9c1f-0fc7e3d7e8cd',
  'https://firebasestorage.googleapis.com/v0/b/onyv-production.appspot.com/o/activity-photos%2Fhanif-mahmad-CbMLzxrvwcg-unsplash.jpg?alt=media&token=db63eb7d-2da2-45e3-b240-535ccd4d780b',
  'https://firebasestorage.googleapis.com/v0/b/onyv-production.appspot.com/o/activity-photos%2Fhenry-perks-T-1t1Q1rBn4-unsplash.jpg?alt=media&token=363f5ed6-f6f2-4b75-b34a-29441ab66379',
  'https://firebasestorage.googleapis.com/v0/b/onyv-production.appspot.com/o/activity-photos%2Fjenny-hill-mQVWb7kUoOE-unsplash.jpg?alt=media&token=df311a0e-978b-4bf6-84de-64f120cbc731',
  'https://firebasestorage.googleapis.com/v0/b/onyv-production.appspot.com/o/activity-photos%2Fjohny-goerend-pnigODapPek-unsplash.jpg?alt=media&token=b91d103e-724f-4a9b-b98f-121b91ef0c8b',
  'https://firebasestorage.googleapis.com/v0/b/onyv-production.appspot.com/o/activity-photos%2Fjulian-hochgesang-jyoTLVMv9So-unsplash.jpg?alt=media&token=3b5cb35f-8aca-4fcc-839a-83851853d619',
  'https://firebasestorage.googleapis.com/v0/b/onyv-production.appspot.com/o/activity-photos%2Fkuno-schweizer-s81Amm6yGLE-unsplash.jpg?alt=media&token=7f3fb544-5c56-4f63-85ba-52a131312419',
  'https://firebasestorage.googleapis.com/v0/b/onyv-production.appspot.com/o/activity-photos%2Fleonard-cotte-0PqjcsYiPfU-unsplash.jpg?alt=media&token=998f6f31-adea-4dbc-a644-6d18d322cca1',
  'https://firebasestorage.googleapis.com/v0/b/onyv-production.appspot.com/o/activity-photos%2Fnathan-queloz-Lms9-4zsVnE-unsplash.jpg?alt=media&token=533be980-85e5-4239-8836-d85e90315213',
  'https://firebasestorage.googleapis.com/v0/b/onyv-production.appspot.com/o/activity-photos%2Fpeter-bucks-2_FaxYZiIA4-unsplash.jpg?alt=media&token=676c5f27-cc04-4633-8c94-e4ea5446b9e2',
  'https://firebasestorage.googleapis.com/v0/b/onyv-production.appspot.com/o/activity-photos%2Fquenten-janssen-W_z4awYQgO4-unsplash.jpg?alt=media&token=5c974993-27e9-4b03-92fa-58c2a5dab0d2',
  'https://firebasestorage.googleapis.com/v0/b/onyv-production.appspot.com/o/activity-photos%2Fsepp-rutz-_Va7Ubkea0o-unsplash.jpg?alt=media&token=3c965019-ab8a-4aa7-aec6-5c417370e9f3',
  'https://firebasestorage.googleapis.com/v0/b/onyv-production.appspot.com/o/activity-photos%2Fvisit-almaty-wN4D-mVR7fE-unsplash.jpg?alt=media&token=aeed909c-6d51-49f0-97a3-c6c0cc072aa3'
]

const pickRandomImages = (amount) => {
  const list = [...imageList]
  let randomImages = []
  Array.from({ length: amount}).forEach(() => {
    const randomIndex = Math.floor(Math.random() * list.length);
    const randomImage = list.splice(randomIndex, 1)[0]
    randomImages.push(randomImage)
  })
  return randomImages
}

function RandomImagePicker({setActivityImageURL}) {
  const classes = useStyles();
  const [randomImageURLs, setRandomImageURLs] = useState([]);

  useEffect(() => {
    setRandomImageURLs(pickRandomImages(3))
  }, [setActivityImageURL])

  return(
    <Box display="flex" justifyContent={'space-between'}>
      { randomImageURLs.map((randomImage) => (
          <Card className={classes.media} key={randomImage}>
            <CardActionArea onClick={() => setActivityImageURL(randomImage)}>
              <CardMedia
                className={classes.media}
                image={randomImage}
              />
            </CardActionArea>
          </Card>
        )) }
    </Box>
  )
}

export default RandomImagePicker;
