import React, { useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useForm } from 'react-hook-form';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import * as dayJs from 'dayjs';
import firebase from '../../config/firebase';
import { useDropzone } from 'react-dropzone';
import { v4 as uuid } from 'uuid';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import FormHelperText from '@material-ui/core/FormHelperText';
import RandomImagePicker from '../activity-dialog/RandomImagePicker';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  media: {
    height: 140,
  },
}));

function CustomUrlGenerator() {
  const classes = useStyles();
  const { register, handleSubmit, errors, formState } = useForm();
  const { t } = useTranslation();
  const initDate = dayJs().hour(20).minute(0).second(0).millisecond(0).add(1, 'day');
  const [selectedDate, setSelectedDate] = useState(initDate);
  const [startTime, setStartTime] = useState(initDate);
  const [activityImageURL, setActivityImageURL] = useState(null);
  const [endTime, endTimeChange] = useState(initDate.add(1, 'hour'));
  const [uploadingImage, setUploadingImage] = useState(false);
  const onDrop = useCallback(async (acceptedFiles) => {
    setUploadingImage(true);
    const image = acceptedFiles[0];
    const extension = image.name.split('.').pop();
    const storageRef = firebase.storage().ref();
    const newImage = storageRef.child(`activities/${uuid()}.${extension}`);
    await newImage.put(image);
    const imageUrl = await newImage.getDownloadURL();
    setActivityImageURL(imageUrl);
    setUploadingImage(false);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'image/jpeg, image/png',
  });

  const createActivity = (formValues) => {
    const date = selectedDate.second(0).millisecond(0);
    const startDate = date
      .hour(startTime.hour())
      .minute(startTime.minute())
      .toDate();
    const { title, privateDescription, publicDescription } = formValues;
    const queryAttrs = {
      prefilled: 'truee',
      date: startDate.toISOString(),
      title,
      activityImageURL,
      publicDescription,
      privateDescription
    }

    const paramString = Object.keys(queryAttrs).reduce((acc, paramKey) => {
      const paramValue = queryAttrs[paramKey]
      if (paramValue) {
        return acc + `&${paramKey}=${paramValue}`
      }

      return acc
    })

    console.log(`https://onyv.ch/?${paramString}`)
    navigator.clipboard.writeText(`https://onyv.ch/?${paramString}`)
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} onSubmit={handleSubmit(createActivity)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                defaultValue={null}
                id="title"
                label={t('activity_form.title')}
                name="title"
                inputRef={register({
                  required: true,
                  minLength: {
                    value: 8,
                    message: t('error.title_too_short'),
                  },
                })}
                inputProps={{ maxLength: 80 }}
                error={!!errors.title}
                helperText={errors.title && errors.title.message}
              />
            </Grid>

            <Grid item xs={12}>
              <DatePicker
                cancelLabel={t('common.cancel')}
                autoOk
                label={t('activity_form.date')}
                value={selectedDate}
                onChange={setSelectedDate}
                disablePast
                disableToolbar
                fullWidth
                required
                inputVariant="outlined"
                name="date"
                inputRef={register({ required: true, minLength: 2 })}
                error={!!errors.date}
                helperText={errors.date && errors.date.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TimePicker
                autoOk
                ampm={false}
                label={t('activity_form.start_time')}
                value={startTime}
                minutesStep={5}
                onChange={setStartTime}
                fullWidth
                required
                inputVariant="outlined"
                name="startTime"
                inputRef={register({ required: true })}
                error={!!errors.startTime}
                helperText={errors.startTime && errors.startTime.message}
                cancelLabel={t('common.cancel')}
              />
            </Grid>

            <Grid item xs={12}>
              <TimePicker
                autoOk
                ampm={false}
                label={t('activity_form.end_time')}
                value={endTime}
                minutesStep={5}
                onChange={endTimeChange}
                fullWidth
                required
                inputVariant="outlined"
                name="endTime"
                inputRef={register({
                  required: true,
                  validate: () => {
                    if (startTime.isAfter(endTime)) {
                      return t(
                        'error.end_time_should_come_after_start_time'
                      );
                    } else if (endTime.diff(startTime, 'minute') < 15) {
                      return t(
                        'error.activity_should_take_min_15'
                      );
                    }
                  },
                })}
                error={!!errors.endTime}
                helperText={errors.endTime && errors.endTime.message}
                cancelLabel={t('common.cancel')}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="public-description"
                label={t('activity_form.public_description')}
                defaultValue={null}
                multiline
                maxRows={10}
                variant="outlined"
                fullWidth
                required
                inputRef={register({
                  required: true,
                  minLength: {
                    value: 8,
                    message: t('error.description_too_short'),
                  },
                })}
                name="publicDescription"
                inputProps={{ maxLength: 1000 }}
                error={!!errors.publicDescription}
                helperText={
                  (errors.publicDescription &&
                    errors.publicDescription.message) ||
                  t('activity_form.public_description_hint')
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="private-description"
                label={t('activity_form.private_description')}
                defaultValue={null}
                multiline
                maxRows={10}
                variant="outlined"
                fullWidth
                required
                inputRef={register({
                  required: true,
                  minLength: {
                    value: 8,
                    message: t('error.description_too_short'),
                  },
                })}
                name="privateDescription"
                inputProps={{ maxLength: 2000 }}
                error={!!errors.privateDescription}
                helperText={
                  (errors.privateDescription &&
                    errors.privateDescription.message) ||
                  t('activity_form.private_description_hint')
                }
              />
            </Grid>

            <Grid item xs={12} hidden={uploadingImage || activityImageURL}>
              <RandomImagePicker setActivityImageURL={setActivityImageURL} />
              <Box display="flex" justifyContent="center" pt={2}>
                <FormHelperText>
                  {t('activity_form.choose_photo_hint')}
                </FormHelperText>
              </Box>
            </Grid>

            <Grid item xs={12} {...getRootProps()}>
              {uploadingImage && (
                <>
                  <Grid container justifyContent="center">
                    <Box m={2}>
                      <CircularProgress />
                    </Box>
                  </Grid>
                </>
              )}
              {!uploadingImage && !activityImageURL && (
                <>
                  <Box mb={-1.5}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      size="large"
                      className={classes.button}
                      startIcon={<CloudUploadIcon />}
                    >
                      {t('activity_form.choose_photo')}
                      <input {...getInputProps()} />
                    </Button>
                  </Box>
                </>
              )}
              {!uploadingImage && activityImageURL && (
                <>
                  <Card>
                    <CardHeader
                      className={classes.cardHeader}
                      title={t('meta.activity_image')}
                      action={
                        <IconButton
                          aria-label="settings"
                          onClick={() => setActivityImageURL(null)}
                        >
                          <CloseIcon />
                        </IconButton>
                      }
                      titleTypographyProps={{ variant: 'subtitle1' }}
                    />
                    <CardMedia
                      className={classes.media}
                      image={activityImageURL}
                      title={t('meta.activity_image')}
                    />
                  </Card>
                </>
              )}
            </Grid>
          </Grid>
          <Box mb={1}>
            <Button
              size="large"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={formState.isSubmitting ? true : !activityImageURL}
            >
              Copy prefilled URL
            </Button>
          </Box>
        </form>
      </div>
    </Container>
  );
}
export default CustomUrlGenerator;
