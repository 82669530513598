import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import UpcomingActivityCard from '../../organizer-detail/UpcomingActivityCard';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

function AllActivitiesDialog({ handleClose, open, activities }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      aria-labelledby="form-dialog-title"
    >
      <Box mx={3} my={3}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box mb={2}>
            <Typography
              noWrap
              color="secondary"
              variant="h5"
              component="h2"
              align="center"
            >
              {t('recurring_activity.upcoming')}
            </Typography>
          </Box>
          {activities.map((activity) => {
            return (
              <UpcomingActivityCard activity={activity}></UpcomingActivityCard>
            );
          })}
        </Grid>
      </Box>
    </Dialog>
  );
}

export default AllActivitiesDialog;
