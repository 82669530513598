import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

function TermsAndConditionsDialog({ open, handleClose }) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      aria-labelledby="form-dialog-title"
    >
      <AppBar className={classes.appBar} color="transparent">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Conditions générales d’utilisation
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <Box mt={3}>
          <Typography variant="h6">1. Objet</Typography>
          <Typography variant="body1">
            Les présentes conditions générales d’utilisation régissent
            l’utilisation des sites exploités par NextUp SNC. L’éditeur de ce
            site est la société NextUp SNC basée à Neuchâtel. Les présentes
            conditions générales s’appliquent à l’ensemble du site. Des
            conditions générales supplémentaires peuvent également concerner de
            produits et services supplémentaires. Si ces dernières entrent en
            contradiction avec la présente CGU, les conditions particulières
            font foi.
          </Typography>

          <Typography variant="h6">2. Définitions</Typography>
          <Typography variant="body1">
            Site: les sites de la marque onyv Contenu: toute information
            présente sur le site, texte, graphiques, images et messages Editeur:
            l’éditeur du site Conditions Générales d’utilisation ou CGU: les
            présentes conditions générales, applicables pour l’utilisation du
            site Utilisateur: personne utilisant le site
          </Typography>

          <Typography variant="h6">3. Acceptation des CGU</Typography>
          <Typography variant="body1">
            L’Utilisateur consent à être lié par les CGU dans leur intégralité
            par son utilisation du site. Si l’utilisateur n’accepte pas
            celle-ci, la personne utilisant le site est tenue de le quitter
            immédiatement.
          </Typography>

          <Typography variant="h6">4. Modification des CGU</Typography>
          <Typography variant="body1">
            Les CGU peuvent être modifiés en tout temps par les éditeurs à leur
            entière discrétion. Il incombe à l’utilisateur de vérifier tout
            éventuel changement ou mise à jour de celles-ci a chaque nouvelle
            utilisation du site.
          </Typography>

          <Typography variant="h6">5. Utilisation du Site</Typography>
          <Typography variant="body1">
            Le présent site permet à l’utilisateur d’organiser des activités
            entre amis et passionnés et de trouver également des activités
            organisées selon ses goûts personnels. La personne utilisant le site
            s’engage à utiliser le site de manière licite et conforme au droit
            suisse en vigueur et les présentes CGU. L’utilisateur sera tenu
            responsable pour toute violation de ces derniers.{' '}
          </Typography>

          <Typography variant="h6">
            6. Exactitude des contenus présents sur le site
          </Typography>
          <Typography variant="body1">
            Toutes informations présentes sur le site, ne peuvent être utilisées
            uniquement à titre indicatif. Malgré les efforts engagés dans le
            contrôle de la qualité et exactitude des informations présentes sur
            le site, ce dernier ne peut pas être tenu responsable, sous réserve
            du droit suisse en vigueur, pour toute inexactitude, erreur,
            omission, dommage direct ou indirect de toute nature, causé par la
            consultation et/ou utilisation de données présentes sur le site.{' '}
          </Typography>

          <Typography variant="h6">
            7. Droits de propriété intellectuelle
          </Typography>
          <Typography variant="body1">
            Toutes les informations présentes et le contenu de ce site, ainsi
            que sa structure et la marque onyv sont la propriété intellectuelle
            exclusive de NextUp SNC. Tout les éléments énoncés ci-dessus sont
            protégés par des droits d’auteurs et de propriété intellectuelle.
            Par conséquent, toute reproduction, modification, diffusion,
            intégration, même partielle est strictement interdite sans accord
            écrit préalable de NextUp SNC.{' '}
          </Typography>

          <Typography variant="h6">8. Liens vers d’autres sites</Typography>
          <Typography variant="body1">
            Le site peut contenir des liens vers l’extérieur et en amenant
            l’utilisateur vers d’autres sites web. La personne utilisant le site
            consulte ces liens et les pages qui s’y réfèrent à sa seule et
            entière responsabilité. Dans le cas ou l’utilisateur subirait un
            dommage, quelle que soit la nature et sorte de celui-ci, le site ne
            peut en aucun cas être tenu pour responsable.
          </Typography>

          <Typography variant="h6">9. Protection des données</Typography>
          <Typography variant="body1">
            Le site veille à protéger les données des utilisateurs. Notre
            politique de confidentialité est disponible en complément de la
            présente CGU et disponible à la suite de celle-ci.
          </Typography>

          <Typography variant="h6">10. Exclusion de responsabilité</Typography>
          <Typography variant="body1">
            L’utilisation du site se fait à vos propres risques et le contenu
            est proposé en l’état et NextUp SNC n’offre aucune garantie sur le
            site au niveau de la qualité des informations, de son actualité, de
            son absence de virus ou de problèmes techniques et de contenus
            transgressant les droits d’un tiers. Le site se réserve également le
            droit de suspendre son service, à tout moment et sans préavis, sans
            droit de quelconque compensation ou procédure de recours de la part
            d’un utilisateur ou d’un tiers. Toute responsabilité de NextUp SNC,
            de ses représentants et auxiliaires, envers des dommages résultants
            de l’utilisation et/ou accès du site par l’utilisateur est exclue,
            cela dans la mesure permise par les lois suisses en vigueur. De
            plus, en accédant au site, l’utilisateur s’engage à indemniser
            NextUP SNC et ses représentants et auxiliaires de tout dommage causé
            par son accès et/ou utilisation du site, frais d’avocats et de
            justices inclus.
          </Typography>

          <Typography variant="h6">11. Activités interdites</Typography>
          <Typography variant="body1">
            Le site est un lieu d’échange amical ou la bienséance est un aspect
            essentiel. Pour cela, le site se réserve le droit de supprimer tout
            contenu présent sur le site (messagerie de groupe incluse) contraire
            à celle-ci, cela sans préavis et par notre seul jugement. Ainsi,
            tout contenu jugé violent, pornographique, diffamatoire, haineux,
            illégal, immoral et contraire ou inadéquat à la bienséance sera
            supprimé de notre site, si nous le jugeons en tant que tel. Nous
            nous réservons également le droit de supprimer les comptes
            utilisateurs ayant exercé ou participé dans la génération de contenu
            de type sus-mentionné et d’interdire aux contrevenants de retourner
            sur le site, même sous un autre nom.
          </Typography>

          <Typography variant="h6">12. Droit applicable et for</Typography>
          <Typography variant="body1">
            Les CGU sont soumises au droit suisse en vigueur. Le for exclusif
            est à Neuchâtel, Suisse. Les dispositions relatives au for de
            l’utilisateur demeurent réservées.{' '}
          </Typography>

          <Typography variant="h6">13. Contact</Typography>
          <Typography variant="body1">
            Pour toutes requêtes concernant la présente CGU, veuillez nous
            contacter a l’adresse suivante : <strong>support@onyv.ch</strong>.
          </Typography>
        </Box>
      </Container>
    </Dialog>
  );
}

export default TermsAndConditionsDialog;
