import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyAjKZaA7S1PeBtLXDt-L0ABcMK597vq5CQ",
  authDomain: "onyv-production.firebaseapp.com",
  projectId: "onyv-production",
  storageBucket: "onyv-production.appspot.com",
  messagingSenderId: "58789087425",
  appId: "1:58789087425:web:0d50452ba6f502043aec40",
  measurementId: "G-4P9PZMS4K1"
};

// initialize Firebase instance
firebase.initializeApp(firebaseConfig);

// initialize other services on Firebase instance
firebase.firestore();
firebase.auth();
firebase.storage();
firebase.analytics();

export default firebase;
