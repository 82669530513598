import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

function PrivacyPolicyDialog({ open, handleClose }) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      aria-labelledby="form-dialog-title"
    >
      <AppBar className={classes.appBar} color="transparent">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Politique de traitement des données
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <Box mt={3}>
          <Typography variant="h6">1. Introduction</Typography>
          <Typography variant="body1">
            NextUp SNC respecte votre confidentialité et votre sphère privée. La
            présente Politique de Confidentialité explique (i) quelle
            information est collectée lors de votre accès et utilisation de nos
            pages Internet accessibles à l’adresse onyv.ch (le “site”), (ii)
            l’utilisation dont nous faisons de l’information collectée, et (iii)
            le niveau de sécurité que nous fournissons pour la protection de
            cette dernière. En accédant et en utilisant le site, vous acceptez
            expressément cette Politique de Confidentialité. Celle-ci peut être
            modifiée en tout temps en vue d’adaptation à toute nouvelle pratique
            commerciale, technologique ou modification législative.
          </Typography>

          <Typography variant="h6">2. Collecte de données</Typography>
          <Typography variant="body1">
            Nous ne collectons pas de données personnelles en lien avec votre
            accès et utilisation du site, hormis celles que vous nous confiez
            expressément via le formulaire de création de compte personnel.
            Notamment, nous pourrions collecter vos données personnelles
            concernant votre nom, prénom, adresse e-mail, préférence de langue,
            description ou autres informations qui peuvent servir à vous
            contacter dans le cadre de l’accès et utilisation du site et compte
            personnel (renouvellement de mot de passe, par exemple), ainsi que
            pour vous notifier des nouveautés par notre newsletter. Nous
            collectons également des données techniques anonymes (Google
            Analytics TM) afin d’évaluer la performance et la qualité de notre
            service.
          </Typography>

          <Typography variant="h6">
            3. Utilisation des données collectées
          </Typography>
          <Typography variant="body1">
            Nous utilisons les données collectées transmises, aux fins énoncées
            lors de leur collecte. En particulier, nous utilisons ces données en
            vue de d’afficher les données désirées sur votre profil
            d’utilisateur et afin d’assurer que vous avez l’âge légal
            d’utilisation du site. Les données anonymes que nous collectons sont
            utilisées afin d’améliorer la qualité de l’expérience du site.
          </Typography>

          <Typography variant="h6">4. Communication à des tiers</Typography>
          <Typography variant="body1">
            Nous ne communiquons pas vos Données Personnelles à des tiers, à
            l’exception de nos partenaires et/ou sous-traitants, dans la mesure
            nécessaire à l’exécution des buts pour lesquels elles sont
            collectées.
          </Typography>

          <Typography variant="h6">5. Transmission à l’étranger</Typography>
          <Typography variant="body1">
            Vos données personnelles sont traitées en Suisse, mais peuvent
            également être stockées et exploitées dans d’autres pays, étant
            précisé que certaines de ces localisations peuvent éventuellement
            inclure des pays qui ne garantissent pas nécessairement le même
            degré de protection des données personnelles que la Suisse. En nous
            soumettant ces données, vous acceptez spécifiquement ce transfert.
          </Typography>

          <Typography variant="h6">6. Modifications</Typography>
          <Typography variant="body1">
            Nous pouvons modifier cette Politique de Confidentialité en tout
            temps à sa seule discrétion. Par votre accès et utilisation
            renouvelés du site après toutes modifications, vous acceptez
            expressément la Politique de Confidentialité modifiée. La Politique
            de Confidentialité en vigueur au moment de la collecte et
            utilisation des données régit ces dernières.
          </Typography>

          <Typography variant="h6">
            7. Droit d’accès et rectification
          </Typography>
          <Typography variant="body1">
            Sur requête et sauf obligation légale contraire, nous vous
            fournirons un résumé des données collectées et traitées. Sur requête
            et sous réserve de droit impératif contraire, vous aurez
            l’opportunité de corriger, mettre à jour, modifier ou supprimer ces
            informations en envoyant un e-mail à support@onyv.ch
          </Typography>

          <Typography variant="h6">8. Droit applicable et for</Typography>
          <Typography variant="body1">
            La présente Politique de Confidentialité est soumise au droit
            matériel suisse, à l’exception de ses règles de conflits de lois.
            Toute réclamation, prétention ou litige doivent être soumis aux
            juridictions compétentes du siège de NextUp SNC, sous réserve d’un
            recours au Tribunal Fédéral.
          </Typography>

          <Typography variant="h6">9. Questions</Typography>
          <Typography variant="body1">
            Si vous deviez avoir une question sur la présente Politique de
            Confidentialité, veuillez nous contacter à l’adresse suivante:{' '}
            <strong>support@onyv.ch</strong>.
          </Typography>
        </Box>
      </Container>
    </Dialog>
  );
}

export default PrivacyPolicyDialog;
