import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from '../store/rootReducer';
import thunk from 'redux-thunk';
import { getFirebase } from 'react-redux-firebase';
import { getFirestore } from 'redux-firestore';

export const configureStore = () => {
  // TODO: remove Thunk if not necessary
  const middlewares = [thunk.withExtraArgument({ getFirebase, getFirestore })];
  const composedEnhancer = composeWithDevTools(applyMiddleware(...middlewares));

  // TODO: add hot reloading and only add devToolsEnhancer in dev environment
  const store = createStore(rootReducer, {}, composedEnhancer);

  return store;
};
