import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AutosuggestLocation from '../autosuggest-location/AutosuggestLocation';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import firebase from '../../config/firebase';
import { useDropzone } from 'react-dropzone';
import { v4 as uuid } from 'uuid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { createUser } from '../../common/firestoreService';
import { capitalizeFirstLetter } from '../../common/tools';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@material-ui/pickers';
import dayjs from 'dayjs';
import FormHelperText from '@material-ui/core/FormHelperText';

const renderLabelWithYear = (date) => {
  if (date.isValid()) {
    return date.format('D MMMM YYYY');
  } else {
    return '';
  }
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  media: {
    height: 140,
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
}));

function MyProfileForm(props) {
  const { auth, profile } = props;
  const classes = useStyles();
  const [uploadingImage, setUploadingImage] = useState(false);
  const [profileImageChanged, setProfileImageChanged] = useState(false);
  const [profilePictureUrl, setProfilePictureUrl] = useState(null);
  const [location, setLocation] = useState(null);
  const [birthday, setBirthday] = useState(dayjs(profile.birthday.toDate()));
  const { t } = useTranslation();
  console.log(profile);
  const { register, handleSubmit, formState, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: profile.firstName,
      lastName: profile.lastName,
      aboutMe: profile.aboutMe,
    },
  });

  const onDrop = useCallback(async (acceptedFiles) => {
    setUploadingImage(true);
    const image = acceptedFiles[0];
    const extension = image.name.split('.').pop();
    const storageRef = firebase.storage().ref();
    const newImage = storageRef.child(
      `profile-pictures/${uuid()}.${extension}`
    );
    await newImage.put(image);
    const imageUrl = await newImage.getDownloadURL();
    setProfilePictureUrl(imageUrl);
    setProfileImageChanged(true);
    setUploadingImage(false);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'image/jpeg, image/png',
  });

  const submit = async ({ firstName, lastName, aboutMe }) => {
    const cleanedFirstName = capitalizeFirstLetter(firstName.trim());
    const cleanedLastName = capitalizeFirstLetter(lastName.trim());
    reset({
      firstName: cleanedFirstName,
      lastName: cleanedLastName,
      location: location?.name,
      aboutMe,
    });
    setProfileImageChanged(false);
    const publicName = `${cleanedFirstName} ${cleanedLastName[0].toUpperCase()}.`;
    const newUser = {
      firstName: cleanedFirstName,
      lastName: cleanedLastName,
      publicName,
      location,
      aboutMe: aboutMe.trim(),
      photoURL: profilePictureUrl || profile.photoURL || auth.photoURL,
      birthday: firebase.firestore.Timestamp.fromDate(birthday.toDate()),
    };

    try {
      await createUser(auth.uid, newUser);
    } catch (firebaseError) {
      console.log(firebaseError);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} onSubmit={handleSubmit(submit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} {...getRootProps()}>
              {uploadingImage && (
                <>
                  <Grid container justifyContent="center">
                    <Box m={2}>
                      <CircularProgress />
                    </Box>
                  </Grid>
                </>
              )}
              {!uploadingImage && (
                <Box m={1} align="center">
                  <input {...getInputProps()} />
                  <Avatar
                    alt={auth.displayName}
                    src={profilePictureUrl || profile.photoURL}
                    className={classes.avatar}
                  />
                  <Typography variant="caption" color="textSecondary">
                    {t('user_profile.change_profile_photo')}
                  </Typography>
                </Box>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label={t('user_profile.first_name')}
                inputRef={register({ required: true })}
                name="firstName"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label={t('user_profile.last_name')}
                helperText={t('user_profile.last_name_hint')}
                inputRef={register({ required: true })}
                name="lastName"
              />
            </Grid>

            <Grid item xs={12}>
              <DatePicker
                autoOk
                label={t('user_profile.birthday')}
                cancelLabel={t('common.cancel')}
                disableFuture
                maxDate={dayjs().subtract(18, 'year').toDate()}
                fullWidth
                value={birthday}
                onChange={setBirthday}
                required
                inputVariant="outlined"
                name="birthday"
                openTo="year"
                views={['year', 'month', 'date']}
                inputRef={register({ required: true })}
                labelFunc={renderLabelWithYear}
              />
            </Grid>

            <Grid item xs={12}>
              <AutosuggestLocation
                name="location"
                autoComplete="off"
                activity="empty"
                searchType="myProfile"
                label={t('user_profile.city')}
                setCoordinatesAndAddress={setLocation}
                presetLocation={profile.location}
                inputRef={register()}
                canBeEmpty={true}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="outlined-multiline-static"
                label={t('user_profile.about_me')}
                autoComplete="off"
                multiline
                maxRows={10}
                variant="outlined"
                fullWidth
                inputRef={register()}
                name="aboutMe"
                inputProps={{ maxLength: 1000 }}
                helperText={t('user_profile.about_me_hint')}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={
              !(profileImageChanged || formState.isDirty) ||
              formState.isSubmitting
            }
          >
            {t('common.update')}
          </Button>
          {formState.isSubmitSuccessful && (
            <Box display="flex" justifyContent="center">
              <FormHelperText>
                {t('user_profile.profile_updated')}
              </FormHelperText>
            </Box>
          )}
        </form>
      </div>
    </Container>
  );
}

export default MyProfileForm;
