// all Firebase functions used in components are added here

import firebase from '../config/firebase';

export const signInWithEmail = (creds) =>
  firebase.auth().signInWithEmailAndPassword(creds.email, creds.password);

export const signUpWithEmail = (creds) =>
  firebase.auth().createUserWithEmailAndPassword(creds.email, creds.password);

export const signInWithFacebook = () =>
  firebase.login({ provider: 'facebook', type: 'redirect' });

export const signInWithGoogle = () =>
  firebase.login({ provider: 'google', type: 'redirect' });

export const signOutFirebase = () => firebase.logout();
