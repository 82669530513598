import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ActivityCard from '../activities/ActivityCard';
import React from 'react';

function MyActivitiesList({ title, activities, firstElement }) {
  return (
    <Box mx="auto" mt="auto" minwidth={380} maxWidth={700} px="2%">
      <Box>
        <Box mt={firstElement ? 2 : 8} mb={2}>
          <Typography
            noWrap
            color="secondary"
            variant="h5"
            component="h2"
            align="left"
          >
            {title}
          </Typography>
        </Box>
        <Grid
          container
          spacing={3}
          direction="column"
          justifyContent="space-evenly"
          alignevents="stretch"
          minwidth={500}
        >
          {activities.map((activity) => (
            <Grid item xs key={activity.id}>
              <ActivityCard activity={activity} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default MyActivitiesList;
