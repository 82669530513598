import React from 'react';
import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useTranslation } from 'react-i18next';

function InstallButton() {
  const { t } = useTranslation();
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener('beforeinstallprompt', handler);

    return () => window.removeEventListener('transitionend', handler);
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  if (!supportsPWA) {
    return null;
  }
  return (
    <Button
      color="primary"
      variant="contained"
      size="small"
      onClick={onClick}
      startIcon={<GetAppIcon />}
    >
      {t('call_to_action.install_app')}
    </Button>
  );
}

export default InstallButton;
