import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import WebFontLoader from 'webfontloader';
import { configureStore } from './app/config/store';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import firebase from './app/config/firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { BrowserRouter } from 'react-router-dom';
import './app/common/i18n';

WebFontLoader.load({
  google: {
    families: ['Material Icons', 'Roboto:300,400,500,700'],
  },
});

const store = configureStore();
const rrfConfig = {
  userProfile: 'users',
  attachAuthIsReady: false,
  useFirestoreForProfile: true,
};
const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback="Loading...">
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ReactReduxFirebaseProvider>
      </Provider>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
