import CardActionArea from '@material-ui/core/CardActionArea';
import { NavLink } from 'react-router-dom';
import { translatedRoutes } from '../common/routes';
import CardContent from '@material-ui/core/CardContent';
import { Card, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import dayjs from 'dayjs';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: '1rem',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cover: {
    width: 150,
  },
}));
function UpcomingActivityCard({ activity }) {
  const classes = useStyles();
  const { i18n } = useTranslation();

  return (
    <Card className={classes.root}>
      <CardActionArea
        component={NavLink}
        to={`${
          !activity.startDate
            ? translatedRoutes[i18n.language].recurringActivityDetailWithoutId
            : translatedRoutes[i18n.language].activityDetailWithoutId
        }/${activity.id}`}
      >
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h5" variant="h5">
              {activity.title}
            </Typography>
            <Box mb={activity.startDate ? 0 : 1}>
              <Typography
                noWrap
                align="left"
                variant="body2"
                color="secondary"
                component="p"
              >
                {activity.location.city}
              </Typography>
            </Box>
            {activity.startDate && (
              <Grid item xs zeroMinWidth>
                <Box
                  display="flex"
                  justify-content="space-between"
                  mt={1}
                  mb={0}
                >
                  <TodayRoundedIcon color="secondary" fontSize="small" />
                  <Box mx={0.4}>
                    <Typography
                      noWrap
                      color="secondary"
                      variant="body2"
                      component="p"
                      align="left"
                    >
                      {dayjs(activity.startTime).format('DD/MM/YY')}
                    </Typography>
                  </Box>
                  <Box ml={2}>
                    <ScheduleRoundedIcon color="secondary" fontSize="small" />
                  </Box>
                  <Box mx={0.4}>
                    <Typography
                      noWrap
                      color="secondary"
                      variant="body2"
                      component="p"
                      align="left"
                    >
                      {dayjs(activity.startTime).format('HH:mm')} -{' '}
                      {dayjs(activity.endTime).format('HH:mm')}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            )}
          </CardContent>
        </div>
      </CardActionArea>
      <CardMedia
        className={classes.cover}
        image={activity.activityImageURL}
        title="Live from space album cover"
      />
    </Card>
  );
}

export default UpcomingActivityCard;
