import React from 'react';
import { Dialog, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(25),
    height: theme.spacing(25),
  },
}));

function ParticipantDetails({ handleClose, open, participant }) {
  const classes = useStyles();
  return (
    <Dialog maxWidth="xs" open={open} fullWidth={true} onClose={handleClose}>
      {participant && (
        <>
          <Box mx={4} my={4}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Avatar src={participant.photoURL} className={classes.avatar} />
              <Box mt={2} px="2%">
                <Typography
                  color="primary"
                  variant="h5"
                  component="h2"
                  align="center"
                  noWrap
                >
                  {participant.publicName}
                </Typography>
              </Box>
              <Box mt={2} pb={2} px="2%">
                <Typography
                  color="secondary"
                  variant="body2"
                  component="p"
                  align="left"
                  NoWrap
                >
                  {participant.aboutMe}
                </Typography>
              </Box>
            </Grid>
          </Box>
        </>
      )}
    </Dialog>
  );
}

export default ParticipantDetails;
