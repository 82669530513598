import React from 'react';
import Drawer from './app/components/layout/Drawer';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
// https://stackoverflow.com/a/64135466/5724886
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import FirstVisitDialog from './app/components/first-visit/FirstVisitDialog';
import { useSelector } from 'react-redux';
import frLocale from 'dayjs/locale/fr';
import * as palette from '@material-ui/core/colors';
import CustomUrlGenerator from './app/components/custom-url-generator/CustomUrlGenerator';
import BetaContainer from './app/components/beta/BetaContainer';

const theme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      root: {
        elevation: 10,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 28,
      },
      contained: {
        boxShadow: 'none',
      },
      label: {
        color: palette.common.white,
      },
    },
  },
  shape: {
    borderRadius: 12,
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: '#44fb8c',
    },
    secondary: {
      main: '#707070',
    },
  },
});

function App() {
  const profile = useSelector((state) => state.firebase.profile);
  const auth = useSelector((state) => state.firebase.auth);
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DayjsUtils} locale={frLocale}>
        <Switch>
          <Route
            path="/custom-url-generator"
            render={() => <CustomUrlGenerator />}
          />
          <Route path="/beta" render={() => <BetaContainer />} />
          <Route
            path="/"
            render={() => (
              <>
                <Drawer />
                {auth.isLoaded &&
                  !auth.isEmpty &&
                  profile.isLoaded &&
                  profile.isEmpty && <FirstVisitDialog />}
              </>
            )}
          />
        </Switch>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
