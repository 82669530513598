import axios from 'axios';

export const parsePlaceRequest = (req, searchType) => {
  const mapBoxSearchTypes = {
    street: 'address',
    city: 'place',
  };

  return axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${req.replace(
      / /g,
      '%20'
    )}.json?country=ch%2Cbe%2Cfr%2Cde&proximity=ip&types=${
      mapBoxSearchTypes[searchType]
    }&language=fr&access_token=pk.eyJ1IjoicHZsYWQzMjEiLCJhIjoiY2xmcHB4dTlhMGJqbjNzcGZjaW9wanVzYyJ9.N0qpdtrp0RN8Z_qgeLEQvg`
  );
};
