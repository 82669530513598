import React from 'react';
import TermsAndConditionsDialog from './TermsAndConditionsDialog';
import PrivacyPolicyDialog from './PrivacyPolicyDialog';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

function ConditionsAndPrivacyPolicyButton() {
  const [openConditions, setOpenConditions] = React.useState(false);
  const [openPrivacy, setOpenPrivacy] = React.useState(false);
  const handleOpenConditions = () => setOpenConditions(true);
  const handleCloseConditions = () => setOpenConditions(false);
  const handleOpenPrivacy = () => setOpenPrivacy(true);
  const handleClosePrivacy = () => setOpenPrivacy(false);
  const { t } = useTranslation();

  return (
    <Box>
      <FormHelperText
        onClick={handleOpenPrivacy}
        style={{ cursor: 'pointer', textAlign: 'center' }}
      >
        {t('side_menu.privacy_policy')}
      </FormHelperText>
      <FormHelperText
        onClick={handleOpenConditions}
        style={{ cursor: 'pointer', textAlign: 'center' }}
      >
        {t('side_menu.terms_and_conditions')}
      </FormHelperText>
      <TermsAndConditionsDialog
        open={openConditions}
        handleClose={handleCloseConditions}
      />
      <PrivacyPolicyDialog
        open={openPrivacy}
        handleClose={handleClosePrivacy}
      />
    </Box>
  );
}

export default ConditionsAndPrivacyPolicyButton;
