import React, { useEffect, useRef } from 'react';
import firebase from '../../config/firebase';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import { useForm } from 'react-hook-form';
import { sendMessage } from '../../common/firestoreService';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  form: {
    display: 'flex',
    marginTop: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  ownMessage: {
    textAlign: 'right',
    flexDirection: 'row-reverse',
  },
  chatbox: {
    flex: 1,
    overflowY: 'scroll',
    overflowX: 'hidden',
    'scrollbar-width': 'none', /* Firefox */
    '-ms-overflow-style': 'none' /* Internet Explorer 10+ */
  },
  chatInput: {
    position: 'sticky',
    bottom: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    backgroundColor: 'white',
  },
  chatContent: {
    overflowWrap: 'anywhere',
  },
  yourChatBubble: {
    backgroundColor: 'whitesmoke',
    borderRadius: '10px 10px 0px 10px;',
    marginTop: '5px',
    marginBottom: '5px',
    paddingRight: '10px',
    paddingLeft: '10px',
    paddingBottom: '5px',
    paddingTop: '5px',
    display: 'inline-block',
  },
  theirChatBubble: {
    backgroundColor: '#44fb8c',
    borderRadius: '10px 10px 10px 0px',
    marginTop: '5px',
    marginBottom: '5px',
    paddingRight: '10px',
    paddingLeft: '10px',
    paddingBottom: '4px',
    paddingTop: '4px',
    display: 'inline-block',
  },
}));

function ActivityChat({ activityID }) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const chatBottomRef = useRef(null);
  const [messages, setMessages] = React.useState([]);
  const { register, handleSubmit } = useForm();
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const { t } = useTranslation();

  dayjs.extend(isToday);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('activities')
      .doc(activityID)
      .collection('messages')
      .orderBy('timestamp')
      .limitToLast(50)
      .onSnapshot((snapshot) => {
        let newMessages = [];
        snapshot.forEach((doc) => {
          if (doc.data().timestamp) {
            const newTimestamp = dayjs.unix(doc.data().timestamp.seconds);
            newMessages.push({
              ...doc.data(),
              id: doc.id,
              timestamp: newTimestamp,
            });
          }
        });

        setMessages(newMessages);
        chatBottomRef.current.scrollIntoView({ behavior: 'smooth' });
      });

    return () => unsubscribe();
  }, [activityID]);

  const handleMessage = async ({ message }, e) => {
    e.target.reset();
    e.target.focus();

    const newMessage = {
      value: message,
      userID: auth.uid,
      name: profile.publicName,
      photoURL: profile.photoURL,
    };

    try {
      await sendMessage(activityID, newMessage);
    } catch (firebaseError) {
      console.log(firebaseError);
    }
  };

  return (
    <Container
      component="main"
      className={classes.container}
      style={{ height: fullScreen ? '100%' : '40vh' }}
    >
      <Box className={classes.chatbox} css={{    '&::-webkit-scrollbar': {
          display: 'none',
        },}}>
        {messages?.length === 0 && (
          <Box display="flex" justifyContent="center">
            <FormHelperText>{t('activity_chat.say_hi')}</FormHelperText>
          </Box>
        )}
        {messages.map((message) => (
          <>
            {message.userID !== auth.uid && (
              <div key={message.id}>
                <Box alignItems="center" display="flex">
                  <Box pr={1}>
                    <Avatar
                      alt={message.name} //initials will be displayed if no pic
                      src={message.photoURL}
                      className={classes.small}
                    />
                  </Box>
                  <Box pr={1}>
                    <Typography variant="caption">{message.name}</Typography>
                  </Box>
                  <Box>
                    <FormHelperText>
                      {dayjs(message.timestamp).isToday()
                        ? message.timestamp.format('H:mm')
                        : message.timestamp.format('D MMM, H:mm')}
                    </FormHelperText>
                  </Box>
                </Box>
                <div className={classes.theirChatBubble}>
                  <Typography
                    className={classes.chatContent}
                    variant="subtitle1"
                  >
                    {message.value}
                  </Typography>
                </div>
              </div>
            )}
            {message.userID === auth.uid && (
              <Box key={message.id} className={classes.ownMessage}>
                <Grid
                  container
                  className={classes.ownMessage}
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item>
                        <FormHelperText>
                          {dayjs(message.timestamp).isToday()
                            ? message.timestamp.format('H:mm')
                            : message.timestamp.format('D MMM, H:mm')}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <div className={classes.yourChatBubble}>
                  <Typography
                    className={classes.chatContent}
                    variant="subtitle1"
                  >
                    {message.value}
                  </Typography>
                </div>
              </Box>
            )}
          </>
        ))}
        <div ref={chatBottomRef} />
      </Box>
      <Box className={classes.chatInput}>
        <form onSubmit={handleSubmit(handleMessage)} className={classes.form}>
          <TextField
            name="message"
            label={t('activity_chat.type_message')}
            inputRef={register({ required: true })}
            autoComplete="off"
            autoFocus={true}
            fullWidth={true}
          />
          <IconButton aria-label="send" color="primary" type="submit">
            <SendIcon />
          </IconButton>
        </form>
      </Box>
    </Container>
  );
}

export default ActivityChat;
