import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import { signOutFirebase } from '../../common/firebaseService';
import { Chip, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { translatedRoutes } from '../../common/routes';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  },
}));

function UserMenu({ profile }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    signOutFirebase();
  };

  return (
    <span style={{ padding: '12px' }}>
      {!onMobile && (
        <Chip
          avatar={<Avatar src={profile.photoURL} className={classes.avatar} />}
          label={profile.firstName}
          onClick={handleMenu}
        />
      )}
      {onMobile && (
        <Avatar
          src={profile.photoURL}
          className={classes.avatar}
          onClick={handleMenu}
        />
      )}

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() =>
            history.push(translatedRoutes[i18n.language].myProfile)
          }
        >
          {profile.firstName} {profile.lastName}
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          {t('authentication.log_out')}
        </MenuItem>
      </Menu>
    </span>
  );
}

export default UserMenu;
