import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {
  makeStyles,
  MuiThemeProvider,
  useTheme,
} from '@material-ui/core/styles';
import { NavLink, Route, Switch, useLocation } from 'react-router-dom';
import Activities from '../activities/Activities';
import MyActivities from '../my-activities/MyActivities';
import MyProfile from '../my-profile/MyProfile';
import Inbox from '../inbox/Inbox';
import { useSelector } from 'react-redux';
import AuthDialogBtn from '../auth-dialog/AuthDialogBtn';
import Grid from '@material-ui/core/Grid';
import AutosuggestLocation from '../autosuggest-location/AutosuggestLocation';
import ReportIssueButton from '../report-issue/ReportIssueButton';
import Link from '@material-ui/core/Link';
import ActivityDetail from '../activities/ActivityDetail';
import UserMenu from './UserMenu';
import onyv from '../layout/onyv_logo.png';
import ConditionsAndPrivacyPolicyButton from '../terms-and-conditions/ConditionsAndPrivacyPolicyButton';
import { useTranslation } from 'react-i18next';
import { Card, createMuiTheme, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ActivityDialogButton from '../activity-dialog/ActivityDialogButton';
import { translatedRoutes } from '../../common/routes';
import NotificationPanel from '../notification-panel/NoficationPanel';
import IntroCarousel from '../intro-carousel/IntroCarousel';
import AuthDialog from '../auth-dialog/AuthDialog';
import * as queryString from 'query-string';
import CustomActivityDialog from '../custom-activity-dialog/CustomActivityDialog';
import InstallButton from '../pwa/install-button';
import RecurringActivityDetail from '../recurring-activities/RecurringActivityDetail';
import OurOrganizers from '../our-organizers/OurOrganizers';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  card: {
    borderRadius: 28,
    border: '0.25px solid #44fb8c',
    boxShadow: 'none',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      zIndex: theme.zIndex.drawer + 1,
      position: 'top',
    },
    backgroundColor: 'white',
    //borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingRight: '284px', // center content by compensating for drawer menu
    },
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  drawerHeader: {
    width: drawerWidth,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'center',
  },
  userName: {
    padding: theme.spacing(0, 1),
  },
  auth: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  location: {
    alignItems: 'left',
  },
  locationPaper: {
    padding: '2px 4px',
    display: 'flex',
  },
  locationInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  drawerFooter: {
    position: 'fixed',
    bottom: 0,
    textAlign: 'center',
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(7),
  },
  active: {
    backgroundColor: 'red',
  },
}));

const themeList = createMuiTheme({
  overrides: {
    MuiListItem: {
      root: {
        color: '#707070',
        '&$selected': {
          backgroundColor: 'transparent',
          color: '#44fb8c',
        },
        '&$selected:hover': {
          backgroundColor: 'transparent',
        },
      },
      '&:hover': {
        backgroundColor: 'transparent',
        color: '#44fb8c',
      },
    },
  },
});

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [authDialogOpen, setAuthDialog] = React.useState(false);
  const location = useLocation();
  const profile = useSelector((state) => state.firebase.profile);
  const auth = useSelector((state) => state.firebase.auth);
  const [activityCoordinates, setActivityCoordinates] =
    React.useState(undefined);
  const onMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t, i18n } = useTranslation();

  const handleAuthDialogToggle = () => {
    setAuthDialog(!authDialogOpen);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handlerDrawerToggleWhenOpen = () => {
    if (mobileOpen) {
      handleDrawerToggle();
    }
  };

  const drawer = (
    <div>
      <AuthDialog handleClose={handleAuthDialogToggle} open={authDialogOpen} />
      {queryString.parse(location.search).activityImageURL && (
        <CustomActivityDialog prefilled={queryString.parse(location.search)} />
      )}

      <div className={classes.drawerHeader}>
        <Box mt={3}>
          <Typography variant="h6" className={classes.title}>
            <Link color="inherit" to="/" component={NavLink} underline="none">
              <img src={onyv} alt="onyv-logo" height="65px" />
            </Link>
          </Typography>
        </Box>
      </div>
      <Box mt={1}>
        <MuiThemeProvider theme={themeList}>
          <List>
            <ListItem
              button
              component={NavLink}
              key="find-activities"
              to={translatedRoutes[i18n.language].findActivities}
              exact
              activeClassName="Mui-selected"
              onClick={handlerDrawerToggleWhenOpen}
            >
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText primary={t('side_menu.find_activities')} />
            </ListItem>

            {auth.isLoaded && auth.isEmpty && (
              <>
                <ListItem
                  button
                  onClick={handleAuthDialogToggle}
                  key="my-activities"
                  activeClassName="Mui-selected"
                >
                  <ListItemIcon>
                    <StarBorderRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('side_menu.my_activities')} />
                </ListItem>

                <ListItem
                  button
                  onClick={handleAuthDialogToggle}
                  key="my-profile"
                  activeClassName="Mui-selected"
                >
                  <ListItemIcon>
                    <AccountCircleOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('side_menu.my_profile')} />
                </ListItem>
              </>
            )}

            {auth.isLoaded && !auth.isEmpty && (
              <>
                <ListItem
                  button
                  component={NavLink}
                  key="my-activities"
                  to={translatedRoutes[i18n.language].myActivities}
                  activeClassName="Mui-selected"
                  onClick={handlerDrawerToggleWhenOpen}
                >
                  <ListItemIcon>
                    <StarBorderRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('side_menu.my_activities')} />
                </ListItem>

                <ListItem
                  button
                  component={NavLink}
                  key="organizers"
                  to={translatedRoutes[i18n.language].organizers}
                  activeClassName="Mui-selected"
                  onClick={handlerDrawerToggleWhenOpen}
                >
                  <ListItemIcon>
                    <EmojiPeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('side_menu.organizers')} />
                </ListItem>

                <ListItem
                  button
                  component={NavLink}
                  key="my-profile"
                  to={translatedRoutes[i18n.language].myProfile}
                  activeClassName="Mui-selected"
                  onClick={handlerDrawerToggleWhenOpen}
                >
                  <ListItemIcon>
                    <AccountCircleOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('side_menu.my_profile')} />
                </ListItem>
              </>
            )}
          </List>
        </MuiThemeProvider>
      </Box>
      <div className={classes.drawerFooter}>
        <Box pb={2}>
          <InstallButton />
        </Box>
        <ConditionsAndPrivacyPolicyButton />
        <ReportIssueButton />
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <IntroCarousel />
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} elevation={2}>
        <Box minWidth={360}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Box my={1.25} width="100%">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
                spacing={3}
              >
                <Hidden xsDown>
                  <Grid item xs={3}>
                    <Box style={{ width: '232px', textAlign: 'center' }}>
                      <Link
                        color="inherit"
                        to="/"
                        component={NavLink}
                        underline="none"
                      >
                        <img src={onyv} alt="onyv-logo" height="60px" />
                      </Link>
                    </Box>
                  </Grid>
                </Hidden>
                <Grid item xs={9} md={6} className={classes.location}>
                  <Switch key={location}>
                    <Route path="/" exact>
                      <Box mx="auto" mt="auto" maxWidth={525} minWidth={200}>
                        <Card className={classes.card}>
                          <AutosuggestLocation
                            activity="empty"
                            name="city"
                            searchType={'activityCity'}
                            label="City"
                            setCoordinates={setActivityCoordinates}
                          />
                        </Card>
                      </Box>
                    </Route>
                  </Switch>
                </Grid>
                <Grid
                  item
                  xs={3}
                  md={3}
                  style={{
                    textAlign: 'right',
                    display: onMobile ? 'contents' : 'block',
                  }} /* prevents icons from stacking on mobile */
                >
                  {auth.isEmpty && <AuthDialogBtn />}
                  {auth.isLoaded && !auth.isEmpty && (
                    <NotificationPanel userID={auth.uid} />
                  )}
                  {auth.isLoaded && !auth.isEmpty && (
                    <UserMenu profile={profile} />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
      <nav className={classes.drawer} aria-label="main-menu">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <Switch key={location}>
          <Route path="/" exact>
            <Activities activityCoordinates={activityCoordinates} />
          </Route>
          <Route
            path={[
              translatedRoutes.en.myActivities,
              translatedRoutes.fr.myActivities,
            ]}
            exact
          >
            {auth.isLoaded && !auth.isEmpty && (
              <MyActivities userID={auth.uid} />
            )}
          </Route>
          <Route
            path={[
              translatedRoutes.en.organizers,
              translatedRoutes.fr.organizers,
            ]}
            exact
          >
            {<OurOrganizers />}
          </Route>
          <Route
            path={[
              translatedRoutes.en.activityDetail,
              translatedRoutes.fr.activityDetail,
            ]}
            exact
            render={(routeParam) => (
              <ActivityDetail props={routeParam} auth={auth} />
            )}
          />
          <Route
            path={[
              translatedRoutes.en.recurringActivityDetail,
              translatedRoutes.fr.recurringActivityDetail,
            ]}
            exact
            render={(routeParam) => (
              <RecurringActivityDetail props={routeParam} auth={auth} />
            )}
          />
          <Route
            path={[
              translatedRoutes.en.myProfile,
              translatedRoutes.fr.myProfile,
            ]}
            exact
          >
            <MyProfile />
          </Route>
          <Route
            path={[translatedRoutes.en.inbox, translatedRoutes.fr.inbox]}
            exact
          >
            <Inbox />
          </Route>
        </Switch>
        {!profile.isEmpty && <ActivityDialogButton />}
      </main>
    </div>
  );
}

export default ResponsiveDrawer;
