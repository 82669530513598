import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import RequestJoinActivityForm from './RequestJoinActivityForm';

function RequestJoinActivityDialog({
  handleClose,
  open,
  activityID,
  handleRequestSent,
  auth,
  profile,
}) {
  return (
    <Dialog maxWidth="xs" open={open} fullWidth={true} onClose={handleClose}>
      <RequestJoinActivityForm
        handleClose={handleClose}
        handleRequestSent={handleRequestSent}
        activityID={activityID}
        auth={auth}
        profile={profile}
      />
    </Dialog>
  );
}

export default RequestJoinActivityDialog;
