import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MessageIcon from '@material-ui/icons/Message';
import {
  approveJoinRequest,
  denyJoinRequest,
} from '../../common/firestoreService';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Switch } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  approveActive: {
    color: '#4fe8ab',
    '&:hover': {
      color: '#4fe8ab',
    },
  },
  approveInactive: {
    '&:hover': {
      color: '#4fe8ab',
    },
  },
  denyActive: {
    color: '#e8664f',
    '&:hover': {
      color: '#e8664f',
    },
  },
  denyInactive: {
    '&:hover': {
      color: '#e8664f',
    },
  },
  grid: {
    padding: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

export function ApproveRequestFormRow({
  isOrganizer,
  joinRequest,
  increaseParticipantCount,
  decreaseParticipantCount,
  handleDetailsClick,
  setParticipants,
  participants,
}) {
  const classes = useStyles();
  const [joinState, setJoinState] = useState(joinRequest.status);
  const denyRequest = (joinRequest) => {
    if (joinState === 'approved') {
      decreaseParticipantCount();
    }
    setJoinState('denied');
    denyJoinRequest(joinRequest.id, joinRequest.activityID);
    const updatedParticipants = participants.filter(
      (p) => p.userID !== joinRequest.userID
    );
    setParticipants(updatedParticipants);
  };
  const approveRequest = (joinRequest) => {
    increaseParticipantCount();
    setJoinState('approved');
    approveJoinRequest(joinRequest.id, joinRequest.activityID);
    const newParticipant = {
      userID: joinRequest.userID,
      photoURL: joinRequest.photoURL,
      publicName: joinRequest.publicName,
      aboutMe: joinRequest.aboutMe,
    };
    const updatedParticipants = [...participants, newParticipant];
    setParticipants(updatedParticipants);
  };

  return (
    <Grid
      container
      className={classes.grid}
      spacing={1}
      justifyContent={'center'}
      alignItems="center"
    >
      <Grid container item xs={2} justifyContent={'center'}>
        <IconButton onClick={() => handleDetailsClick(joinRequest)}>
          <Avatar
            className={classes.avatar}
            alt={joinRequest.publicName} //initials will be displayed if no pic
            src={joinRequest.photoURL}
          />
        </IconButton>
      </Grid>

      <Grid item xs={isOrganizer ? 5 : 10} justifyContent={!isOrganizer && 'flex-end'}>
        <IconButton
          style={{ backgroundColor: 'transparent' }}
          onClick={() => handleDetailsClick(joinRequest)}
        >
          <Typography style={{ wordWrap: 'break-word' }}>
            {joinRequest.publicName}
          </Typography>
        </IconButton>
      </Grid>

      {isOrganizer && (
        <Grid container item xs={5} justifyContent={'flex-end'}>
          {joinRequest.message && (
            <Tooltip title={joinRequest.message} aria-label="add">
              <IconButton type="submit" aria-label="read-message">
                <MessageIcon />
              </IconButton>
            </Tooltip>
          )}
          <Switch
            checked={joinState === 'approved'}
            onChange={() =>
              joinState !== 'approved'
                ? approveRequest(joinRequest)
                : denyRequest(joinRequest)
            }
            name="requestState"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            color={'primary'}
          />
        </Grid>
      )}
    </Grid>
  );
}
