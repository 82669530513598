import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
//https://react-google-maps-api-docs.netlify.app/

const containerStyle = {
  width: '100%',
  height: '300px',
  borderRadius: '12px'
};

const apiKey = 'AIzaSyBNkInCA27ZDMVH0LuKH5HqiQRyyMOlJwE';
const language = 'fr';

export function CustomGoogleMap({ lat, lng }) {
  return (
    <LoadScript googleMapsApiKey={apiKey} language={language}>
      <GoogleMap
        clickableIcons={true}
        mapContainerStyle={containerStyle}
        center={{ lat, lng }}
        zoom={16}
      >
        <Marker position={{ lat, lng }} />
      </GoogleMap>
    </LoadScript>
  );
}

export default React.memo(CustomGoogleMap);
