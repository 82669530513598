import React, { Fragment, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import firebase from '../../config/firebase';
import { useSelector } from 'react-redux';
import ApproveRequestsDialog from './ApproveRequestsDialog';
import CheckIcon from '@material-ui/icons/Check';
import { useTranslation } from 'react-i18next';
import { Badge } from '@material-ui/core';

function ApproveRequestsButton({
  isOrganizer,
  activityID,
  decreaseParticipantCount,
  increaseParticipantCount,
  handleDetailsClick,
  setParticipants,
  participants
}) {
  const initiallyOpened = !!new URLSearchParams(window.location.search).get(
    'requestsopened'
  );
  const [open, setOpen] = React.useState(initiallyOpened);
  const auth = useSelector((state) => state.firebase.auth);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [requests, setRequests] = React.useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (auth.isLoaded && !auth.isEmpty) {
      firebase
        .firestore()
        .collection('activities')
        .doc(activityID)
        .collection('requests')
        .where('status', '!=', 'organizer')
        .get()
        .then((snapshot) => {
          if (snapshot.docs) {
            const newRequests = snapshot.docs.reduce((acc, doc) => {
              acc.push({ id: doc.id, ...doc.data() });
              return acc;
            }, []);
            setRequests(newRequests);
          }
        });
    }
  }, [activityID, auth.uid, auth.isEmpty, auth.isLoaded, participants]);

  return (
    <Fragment>
      <Badge badgeContent={requests?.filter((r) => r.status === 'pending').length} color={"primary"} fullWidth>
        <Button
          color={requests?.filter((r) => r.status === 'pending').length > 0 ? 'primary' : 'default'}
          variant="contained"
          size="medium"
          onClick={handleOpen}
          startIcon={<CheckIcon />}
        >
          {t('activity_detail.manage_btn')}
        </Button>
      </Badge>
      <ApproveRequestsDialog
        isOrganizer={isOrganizer}
        participants={participants}
        open={open}
        handleClose={handleClose}
        joinRequests={requests}
        decreaseParticipantCount={decreaseParticipantCount}
        increaseParticipantCount={increaseParticipantCount}
        handleDetailsClick={handleDetailsClick}
        setParticipants={setParticipants}
      />
    </Fragment>
  );
}

export default ApproveRequestsButton;
