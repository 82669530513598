import React, { useEffect, useRef } from 'react';
import { Box, Grid, Backdrop, CircularProgress } from '@mui/material';
import ActivityCard from './ActivityCard';
import firebase from '../../config/firebase';
import { geoFirestore } from '../../common/geoFirestoreService';
import { Waypoint } from 'react-waypoint';
import NoMoreActivities from '../call-to-actions/NoMoreActivities';
import HelmetMeta from '../meta/HelmetMeta';
import { useTranslation } from 'react-i18next';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function Activities(props) {
  const { activityCoordinates } = props;
  const [activities, setActivities] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [noMoreActivities, setNoMoreActivities] = React.useState(false);
  const [activitiesLimit, setActivitiesLimit] = React.useState(10);
  const previousActivityCoordinates = usePrevious(activityCoordinates);
  const { t } = useTranslation();

  useEffect(() => {
    if (activityCoordinates) {
      const userCoordinates =
        activityCoordinates &&
        new firebase.firestore.GeoPoint(
          activityCoordinates.lat,
          activityCoordinates.lng
        );

      geoFirestore
        .collection('public-activities')
        .near({ center: userCoordinates, radius: 200 })
        .limit(activitiesLimit)
        .get()
        .then(function (querySnapshot) {
          if (querySnapshot.docs.length < activitiesLimit) {
            setNoMoreActivities(true);
          }

          let newActivities = [];
          querySnapshot.docs
            .sort((a, b) => a.distance - b.distance) // sort by distance from user
            .forEach(function (doc) {
              const activity = doc.data();

              if (activity.deleted) {
                return;
              }

              newActivities.push({
                ...activity,
                id: doc.id,
                startTime: activity.startDate.seconds * 1000,
                endTime: activity.endDate.seconds * 1000,
                kmFromUser: Math.round(doc.distance),
              });
            });

          setActivities(newActivities);
          setLoading(false);
        });
    }
    // disable eslint because it keeps complaining about adding activities.length
    // as a dep, which we can't do now because it would mess up the wanted result
    // as this would setNoMoreActivities to true immediately
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activitiesLimit, previousActivityCoordinates, activityCoordinates]);

  const increaseActivitiesLimit = () => {
    setActivitiesLimit(activitiesLimit + 10);
  };

  if (loading) {
    return (
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else {
    return (
      <Box
        mx="auto"
        mt={{ xs: 2, sm: 5, md: 5, lg: 5, xl: 5 }}
        minwidth={380}
        maxWidth={700}
      >
        <HelmetMeta title={t('meta.activities')} />
        <Grid
          container
          spacing={5}
          direction="column"
          justifyContent="space-evenly"
          alignevents="stretch"
          minwidth={500}
        >
          {activities &&
            activities.map((activity, index) => (
              <Grid item xs key={activity.id}>
                {
                  // load in more activities when scrolling over one before last activity
                  !noMoreActivities && activities.length - 3 === index && (
                    <Waypoint onEnter={() => increaseActivitiesLimit()} />
                  )
                }
                <ActivityCard activity={activity} />
              </Grid>
            ))}

          {noMoreActivities && <NoMoreActivities />}
        </Grid>
      </Box>
    );
  }
}

export default Activities;
