import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useForm } from 'react-hook-form';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import * as dayJs from 'dayjs';
import { createActivity as createActivityService } from '../../common/firestoreService';
import firebase from '../../config/firebase';
import Box from '@material-ui/core/Box';
import { scrambleCoordinate } from '../../common/tools';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  media: {
    height: 140,
  },
}));

function CreateSingleActivityForm({
  activity,
  handleClose,
  createActivityTrigger,
}) {
  const classes = useStyles();
  const { register, handleSubmit, errors, formState } = useForm();
  const { t } = useTranslation();
  const initDate = dayJs()
    .hour(20)
    .minute(0)
    .second(0)
    .millisecond(0)
    .add(1, 'day');
  const [selectedDate, setSelectedDate] = useState(initDate);
  const [startTime, setStartTime] = useState(initDate);
  const [endTime, endTimeChange] = useState(initDate.add(1, 'hour'));

  const createActivity = async (_formValues) => {
    const date = selectedDate.second(0).millisecond(0);
    const startDate = date
      .hour(startTime.hour())
      .minute(startTime.minute())
      .toDate();
    const endDate = date.hour(endTime.hour()).minute(endTime.minute()).toDate();
    const newActivity = {
      startDate: firebase.firestore.Timestamp.fromDate(startDate),
      endDate: firebase.firestore.Timestamp.fromDate(endDate),
      coordinates: new firebase.firestore.GeoPoint(
        activity.coordinates.latitude,
        activity.coordinates.longitude
      ),
      scrambledCoordinates: new firebase.firestore.GeoPoint(
        scrambleCoordinate(activity.coordinates.latitude),
        scrambleCoordinate(activity.coordinates.longitude)
      ),
      publicDescription: activity.publicDescription,
      privateDescription: activity.privateDescription,
      title: activity.title,
      organizerID: activity.organizerID,
      organizerName: activity.organizerName,
      organizerPhotoURL: activity.organizerPhotoURL,
      aboutOrganizer: activity.aboutOrganizer,
      activityImageURL: activity.activityImageURL,
      location: activity.location,
      recurringActivityID: activity.id,
    };
    try {
      await createActivityService(newActivity, null).then(function (
        _createdActivity
      ) {
        handleClose();
        if (createActivityTrigger) {
          setTimeout(() => {
            createActivityTrigger(crypto.randomUUID());
          }, 5000);
        }
      });
    } catch (firebaseError) {
      console.log(firebaseError);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} onSubmit={handleSubmit(createActivity)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DatePicker
                cancelLabel={t('common.cancel')}
                autoOk
                label={t('activity_form.date')}
                value={selectedDate}
                onChange={setSelectedDate}
                disablePast
                disableToolbar
                fullWidth
                required
                inputVariant="outlined"
                name="date"
                inputRef={register({ required: true, minLength: 2 })}
                error={!!errors.date}
                helperText={errors.date && errors.date.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TimePicker
                autoOk
                ampm={false}
                label={t('activity_form.start_time')}
                value={startTime}
                minutesStep={5}
                onChange={setStartTime}
                fullWidth
                required
                inputVariant="outlined"
                name="startTime"
                inputRef={register({ required: true })}
                error={!!errors.startTime}
                helperText={errors.startTime && errors.startTime.message}
                cancelLabel={t('common.cancel')}
              />
            </Grid>

            <Grid item xs={12}>
              <TimePicker
                autoOk
                ampm={false}
                label={t('activity_form.end_time')}
                value={endTime}
                minutesStep={5}
                onChange={endTimeChange}
                fullWidth
                required
                inputVariant="outlined"
                name="endTime"
                inputRef={register({
                  required: true,
                  validate: () => {
                    if (startTime.isAfter(endTime)) {
                      return t('error.end_time_should_come_after_start_time');
                    } else if (endTime.diff(startTime, 'minute') < 15) {
                      return t('error.activity_should_take_min_15');
                    }
                  },
                })}
                error={!!errors.endTime}
                helperText={errors.endTime && errors.endTime.message}
                cancelLabel={t('common.cancel')}
              />
            </Grid>
          </Grid>
          <Box mb={1}>
            <Button
              size="large"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={formState.isSubmitting}
            >
              {t('common.lets_go')}
            </Button>
          </Box>
        </form>
      </div>
    </Container>
  );
}
export default CreateSingleActivityForm;
