import React, { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Avatar from '@material-ui/core/Avatar';
import {
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Typography,
} from '@material-ui/core';
import Badge from '@mui/material/Badge';
import List from '@material-ui/core/List';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { translatedRoutes } from '../../common/routes';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import firebase from '../../config/firebase';
import { markNotificationsAsRead } from '../../common/firestoreService';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  readNotification: {
    color: 'rgba(0, 0, 0, 0.30)',
  },
}));

const notificationTypes = {
  activityChatMessage: 'activity_chat_message',
  activityJoinRequestReceived: 'activity_join_request_received',
  activityJoinRequestApproved: 'activity_join_request_approved',
  someoneLeftActivity: 'someone_left_activity',
};

function JoinRequestReceivedNotification({ notification, handleClose }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const handleClick = () => {
    markNotificationsAsRead(notification.duplicateIDs);
    handleClose();
    history.push(
      `${translatedRoutes[i18n.language].activityDetailWithoutId}/${
        notification.activityID
      }?requestsopened=true`
    );
  };

  return (
    <ListItem alignItems="flex-start" button onClick={handleClick}>
      <ListItemAvatar>
        <Avatar
          alt={notification.activityTitle}
          src={notification.activityImageURL}
        />
      </ListItemAvatar>
      <ListItemText
        className={notification.read && classes.readNotification}
        primary={t('notification.join_request_title')}
        secondary={
          <Typography className={notification.read && classes.readNotification}>
            <Typography
              component="span"
              variant="body2"
              color="textPrimary"
              className={notification.read && classes.readNotification}
            >
              {notification.activityTitle}
            </Typography>

            {` — ${notification.requestUserName} ${t(
              'notification.wants_to_join_you'
            )}`}
          </Typography>
        }
      />
    </ListItem>
  );
}

function JoinRequestApprovedNotification({ notification, handleClose }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const handleClick = () => {
    markNotificationsAsRead(notification.duplicateIDs);
    handleClose();
    history.push(
      `${translatedRoutes[i18n.language].activityDetailWithoutId}/${
        notification.activityID
      }`
    );
  };

  return (
    <ListItem alignItems="flex-start" button onClick={handleClick}>
      <ListItemAvatar>
        <Avatar
          alt={notification.activityTitle}
          src={notification.activityImageURL}
        />
      </ListItemAvatar>
      <ListItemText
        className={notification.read && classes.readNotification}
        primary={t('notification.request_approved_title')}
        secondary={
          <Typography className={notification.read && classes.readNotification}>
            <Typography
              component="span"
              variant="body2"
              color="textPrimary"
              className={notification.read && classes.readNotification}
            >
              {notification.activityTitle}
            </Typography>

            {` — ${t('notification.request_approved')}`}
          </Typography>
        }
      />
    </ListItem>
  );
}

function ChatMessageNotification({ notification, handleClose }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const handleClick = () => {
    markNotificationsAsRead(notification.duplicateIDs);
    handleClose();
    history.push(
      `${translatedRoutes[i18n.language].activityDetailWithoutId}/${
        notification.activityID
      }?chatopened=true`
    );
  };

  return (
    <ListItem alignItems="flex-start" button onClick={handleClick}>
      <ListItemAvatar>
        <Avatar
          alt={notification.activityTitle}
          src={notification.activityImageURL}
        />
      </ListItemAvatar>
      <ListItemText
        className={notification.read && classes.readNotification}
        primary={t('notification.new_message_title')}
        secondary={
          <Typography className={notification.read && classes.readNotification}>
            <Typography
              component="span"
              variant="body2"
              color="textPrimary"
              className={notification.read && classes.readNotification}
            >
              {notification.activityTitle}
            </Typography>

            {` — ${t('notification.new_message')}`}
          </Typography>
        }
      />
    </ListItem>
  );
}

function NotificationPanel({ userID }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifications, setNotifications] = React.useState([]);
  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  useEffect(() => {
    const twoWeeksAgo = new Date(
      new Date().getTime() - 60 * 60 * 24 * 14 * 1000
    );
    return firebase
      .firestore()
      .collection('notifications')
      .where('userID', '==', userID)
      .where('timestamp', '>', twoWeeksAgo)
      .orderBy('timestamp')
      .limitToLast(10)
      .onSnapshot((querySnapshot) => {
        const newNotifications = [];
        const notificationDocs = querySnapshot.docs;
        notificationDocs.forEach((doc, index) => {
          // remove duplicate notifications
          const previousNotification = notificationDocs[index - 1];
          const currentNotification = {
            ...doc.data(),
            id: doc.id,
            duplicateIDs: [doc.id],
          };
          if (previousNotification) {
            const previousNotificationData = previousNotification.data();
            const notificationsMatch =
              previousNotificationData.type === currentNotification.type &&
              currentNotification.type !==
                notificationTypes.activityJoinRequestReceived &&
              previousNotificationData.activityID ===
                currentNotification.activityID;

            if (!notificationsMatch) {
              newNotifications.push(currentNotification);
            } else {
              newNotifications[newNotifications.length - 1].duplicateIDs.push(
                currentNotification.id
              );
            }
          } else {
            newNotifications.push(currentNotification);
          }
        });
        // reversing array to make sure that newest notifications appear on top
        const unreadNotifications = newNotifications
          .filter((notification) => !notification.read)
          .reverse();
        const readNotifications = newNotifications
          .filter((notification) => notification.read)
          .reverse();
        setNotifications([...unreadNotifications, ...readNotifications]);
      });
  }, [userID]);

  return (
    <span>
      <IconButton onClick={handleMenu}>
        <Badge
          badgeContent={
            notifications.filter((notification) => !notification.read).length
          }
          color="primary"
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Popover
        id={'notifications-popover'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {notifications.length === 0 && (
          <Box p={2}>
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
              color="textPrimary"
            >
              {t('notification.no_notifications')}
            </Typography>
          </Box>
        )}
        {notifications.length > 0 && (
          <List
            className={classes.root}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {notifications.map((notification, index) => (
              <>
                {notification.type ===
                  notificationTypes.activityChatMessage && (
                  <ChatMessageNotification
                    notification={notification}
                    handleClose={handleClose}
                  />
                )}
                {notification.type ===
                  notificationTypes.activityJoinRequestReceived && (
                  <JoinRequestReceivedNotification
                    notification={notification}
                    handleClose={handleClose}
                  />
                )}
                {notification.type ===
                  notificationTypes.activityJoinRequestApproved && (
                  <JoinRequestApprovedNotification
                    notification={notification}
                    handleClose={handleClose}
                  />
                )}
                {index !== notifications.length - 1 && (
                  <Divider variant="inset" component="li" />
                )}
              </>
            ))}
          </List>
        )}
      </Popover>
    </span>
  );
}

export default NotificationPanel;
