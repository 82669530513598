import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { EmailShareButton, FacebookIcon } from 'react-share';
import { baseURL, translatedRoutes } from '../../common/routes';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'relative',
    width: 300,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    outline: 0, // remove blue outline
  },
  fbButton: {
    background: '#3C5898',
    color: 'white',
    height: 45,
    width: 200,
    '&:hover, &:focus': {
      background: '#3C5898',
    },
  },
  whButton: {
    background: '#25D366',
    color: 'white',
    height: 45,
    width: 200,
  },
  mailButton: {
    height: 45,
    width: 200,
  },
  copy: {
    width: 200,
  },
}));

function ShareActivityDialog({ open, closeModal, activity }) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const activityUrl =
    baseURL +
    (!activity.startDate
      ? translatedRoutes[i18n.language].recurringActivityDetailWithoutId
      : translatedRoutes[i18n.language].activityDetailWithoutId) +
    '/' +
    activity.id;
  const fbshareUrl = `https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fonyv.ch%2F${
    !activity.startDate
      ? translatedRoutes[i18n.language].recurringActivityDetailWithoutId
      : translatedRoutes[i18n.language].activityDetailWithoutId
  }%2F${activity.id}%2F&amp;src=sdkpreparse`;
  const popheight = window.innerHeight / 2;
  const popwidth = window.innerWidth / 2;
  return (
    <>
      <Dialog
        maxWidth="xs"
        open={open}
        close={closeModal}
        onBackdropClick={closeModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.modal}
        >
          <div className={classes.paper}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box mt={2} px="2%">
                <Typography
                  color="secondary"
                  variant="h5"
                  component="h2"
                  align="left"
                  noWrap
                >
                  {t('common.share')}
                </Typography>
              </Box>
              <Box mt={3}>
                <Button
                  variant="contained"
                  className={classes.fbButton}
                  startIcon={
                    <FacebookIcon
                      bgStyle={{
                        display: 'flex',
                        flexDirection: 'column',
                        color: 'red',
                      }}
                      size={36}
                    />
                  }
                  onClick={() =>
                    window.open(
                      `${fbshareUrl}`,
                      '_blank',
                      `width=200,
                       height=300,
                       top=${popheight - 500}
                       left=${popwidth - 100}  
                       `
                    )
                  }
                >
                  Facebook
                </Button>
              </Box>
              {/*<Box mt={2}>*/}
              {/*  <WhatsappShareButton title="Hey checkout this amazing event ->">*/}
              {/*    <Button*/}
              {/*      variant="contained"*/}
              {/*      className={classes.whButton}*/}
              {/*      startIcon={<WhatsappIcon size={36} />}*/}
              {/*    >*/}
              {/*      Whatsapp*/}
              {/*    </Button>*/}
              {/*  </WhatsappShareButton>*/}
              {/*</Box>*/}

              <Box mt={3}>
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.copy}
                  onClick={() => navigator.clipboard.writeText(activityUrl)}
                  startIcon={<FileCopyIcon />}
                >
                  {t('activity_detail.copy_link_btn')}
                </Button>
              </Box>

              <Box mt={2} mb={3}>
                <EmailShareButton
                  subject={activity.title}
                  body={t('share.check_it_out')}
                  separator=" "
                  url={activityUrl}
                >
                  <Button
                    variant="contained"
                    className={classes.mailButton}
                    startIcon={<MailOutlineIcon size={36} />}
                  >
                    Email
                  </Button>
                </EmailShareButton>
              </Box>
            </Grid>
          </div>
        </Box>
      </Dialog>
    </>
  );
}
export default ShareActivityDialog;
