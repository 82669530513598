import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { createJoinActivityRequest } from '../../common/firestoreService';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function RequestJoinActivityForm({
  handleClose,
  activityID,
  handleRequestSent,
  auth,
  profile,
}) {
  const classes = useStyles();
  const { register, handleSubmit, formState } = useForm({ mode: 'onChange' });
  const { t } = useTranslation();

  const submit = async ({ message }) => {
    const userName = [profile.firstName, profile.lastName]
      .filter((e) => e)
      .join(' ');
    const newRequest = {
      message: message.length === 0 ? null : message,
      userID: auth.uid,
      photoURL: profile.photoURL,
      userName,
      activityID,
      aboutMe: profile.aboutMe,
      firstName: profile.firstName,
      lastName: profile.lastName,
      publicName: profile.publicName,
    };

    try {
      await createJoinActivityRequest(activityID, auth.uid, newRequest);
      handleRequestSent();
      handleClose();
    } catch (firebaseError) {
      console.log(firebaseError);
    }
  };

  return (
    <Container component="main">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} onSubmit={handleSubmit(submit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="outlined-multiline-static"
                label={t('join_request.write_message')}
                variant="outlined"
                fullWidth
                inputRef={register()}
                name="message"
                inputProps={{ maxLength: 140 }}
                helperText={t('join_request.write_message_hint')}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={formState.isSubmitting}
          >
            {t('join_request.join_request_btn')}
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default RequestJoinActivityForm;
