import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import ShareActivityDialog from './ShareActivityDialog';

function ShareActivityMenuButton({activity}) {
  const [showModal, setShowModal] = React.useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return(
    <>
      <IconButton
        align="right"
        color="inherit"
        aria-label="menu"
        onClick={openModal}
      >
        <ShareRoundedIcon color="secondary" />
      </IconButton>
      <ShareActivityDialog closeModal={closeModal} open={showModal} activity={activity} />
    </>
  )
}
export default ShareActivityMenuButton;
