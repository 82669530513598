import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import ActivityChatDialog from './ActivityChatDialog';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { useTranslation } from 'react-i18next';

function ActivityChatButton({ activity }) {
  const initiallyOpened = !!(new URLSearchParams(window.location.search)).get('chatopened');
  const [open, setOpen] = React.useState(initiallyOpened);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();

  return (
    <Fragment>
      <Button
        color="primary"
        variant="contained"
        size="medium"
        onClick={handleOpen}
        startIcon={<ChatBubbleIcon />}
      >
        {t('activity_detail.chat_btn')}
      </Button>
      <ActivityChatDialog
        open={open}
        handleClose={handleClose}
        activity={activity}
      />
    </Fragment>
  );
}

export default ActivityChatButton;
