import React, { useRef } from 'react';
import { Box, Container, Divider, Grid, Typography } from '@material-ui/core';
import onyv from '../layout/onyv_logo.png';
import phone from './phone.png';
import completed from './completed.svg';
import testing from './testing.svg';
import Button from '@material-ui/core/Button';
import AuthDialogBtn from '../auth-dialog/AuthDialogBtn';
import CheckIcon from '@material-ui/icons/Check';
import CardIcon from '@material-ui/icons/CreditCard';
import ChatIcon from '@material-ui/icons/ChatBubble';
import BoostIcon from '@material-ui/icons/OfflineBolt';

function BetaContainer() {
  const infoRef = useRef(null);
  return (
    <Container maxWidth="lg">
      <img
        src={onyv}
        alt="onyv-logo"
        height="65px"
        sx={{ display: 'inline' }}
      />
      <Typography
        noWrap
        align="left"
        color="secondary"
        variant="subtitle1"
        component="h2"
        style={{ display: 'inline-block' }}
      >
        Beta
      </Typography>

      <Box>
        <Grid container spacing={6}>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Box paddingBottom={3}>
              <Typography
                align="left"
                color="secondary"
                variant="h3"
                component="h2"
                style={{ color: '#444444' }}
              >
                Organisez et faites la promotion de vos activités en toute
                simplicité
              </Typography>
            </Box>
            <Typography
              align="left"
              color="secondary"
              variant="body1"
              component="h5"
            >
              Facilitez-vous la vie en tant qu'organisateur d'activités et
              gagnez de l'argent en faisant ce que vous aimez. Créez et
              promouvez facilement vos activités, qu'il s'agisse d'une visite
              guidée, d'un atelier photo ou d'un cours de cuisine.
            </Typography>
            <Box marginTop={3}>
              <Box marginRight={1} display={'inline'}>
                <AuthDialogBtn buttonText={'On y va !'} />
              </Box>
              <Box display={'inline'}>
                <Button
                  onClick={() =>
                    infoRef.current.scrollIntoView({ behavior: 'smooth' })
                  }
                  variant="outlined"
                  color="secondary"
                >
                  <Typography variant={'string'} color="secondary">
                    En savoir plus
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={phone} alt="onyv-phone" width="100%" />
          </Grid>
        </Grid>
      </Box>
      <Divider />

      <Box marginTop={5}>
        <div ref={infoRef} />
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <Box>
              <Box
                borderRadius={16}
                bgcolor={'#ebebeb'}
                height={'56px'}
                width={'56px'}
                display={'flex'}
                direction="column"
                alignItems="center"
                justifyContent="center"
                margin={'auto'}
              >
                <CheckIcon fontSize={'large'} />
              </Box>
              <Box marginTop={1}>
                <Typography
                  align="center"
                  color="secondary"
                  variant="h6"
                  component="h5"
                  style={{ color: '#444444' }}
                >
                  Organisation facile
                </Typography>
              </Box>
              <Box marginTop={1}>
                <Typography
                  align="center"
                  color="secondary"
                  variant="body1"
                  component="h5"
                >
                  Vous pouvez organiser des événements publics ou privés, et
                  autoriser ou refuser l'adhésion à votre activité.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box>
              <Box
                borderRadius={16}
                bgcolor={'#ebebeb'}
                height={'56px'}
                width={'56px'}
                display={'flex'}
                direction="column"
                alignItems="center"
                justifyContent="center"
                margin={'auto'}
              >
                <BoostIcon fontSize={'large'} />
              </Box>
              <Box marginTop={1}>
                <Typography
                  align="center"
                  color="secondary"
                  variant="h6"
                  component="h5"
                  style={{ color: '#444444' }}
                >
                  Boostez vos activités
                </Typography>
              </Box>
              <Box marginTop={1}>
                <Typography
                  align="center"
                  color="secondary"
                  variant="body1"
                  component="h5"
                >
                  Les activités sont répertoriées sur notre page d'accueil. Vous
                  pouvez augmenter la visibilité de votre événement en le
                  promouvant, afin qu’il apparaisse en haut de la page.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box>
              <Box
                borderRadius={16}
                bgcolor={'#ebebeb'}
                height={'56px'}
                width={'56px'}
                display={'flex'}
                direction="column"
                alignItems="center"
                justifyContent="center"
                margin={'auto'}
              >
                <CardIcon fontSize={'large'} />
              </Box>
              <Box marginTop={1}>
                <Typography
                  align="center"
                  color="secondary"
                  variant="h6"
                  component="h5"
                  style={{ color: '#444444' }}
                >
                  Monétisez votre passion
                </Typography>
              </Box>
              <Box marginTop={1}>
                <Typography
                  align="center"
                  color="secondary"
                  variant="body1"
                  component="h5"
                >
                  Organisez des événements premium qui vous permettent d'être
                  rémunéré. Les participants peuvent payer via TWINT ou par
                  carte de crédit.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box>
              <Box
                borderRadius={16}
                bgcolor={'#ebebeb'}
                height={'56px'}
                width={'56px'}
                display={'flex'}
                direction="column"
                alignItems="center"
                justifyContent="center"
                margin={'auto'}
              >
                <ChatIcon fontSize={'large'} />
              </Box>
              <Box marginTop={1}>
                <Typography
                  align="center"
                  color="secondary"
                  variant="h6"
                  component="h5"
                  style={{ color: '#444444' }}
                >
                  Chattez avec les participants
                </Typography>
              </Box>
              <Box marginTop={1}>
                <Typography
                  align="center"
                  color="secondary"
                  variant="body1"
                  component="h5"
                >
                  Chaque activité dispose d'un chat intégré qui vous permet
                  d'interagir avec les participants, sans avoir à partager vos
                  informations Facebook ou WhatsApp.
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box marginTop={5}>
          <Grid container spacing={6}>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Box paddingBottom={2}>
                <Typography
                  align="left"
                  color="secondary"
                  variant="h4"
                  component="h2"
                  style={{ color: '#444444' }}
                >
                  Vous avez le contrôle
                </Typography>
              </Box>
              <Typography
                align="left"
                color="secondary"
                variant="body1"
                component="h5"
              >
                <p>
                  Onyv est parfait pour tous ceux qui souhaitent planifier et
                  gérer des événements avec facilité et précision. Avec notre
                  application, vous aurez un contrôle total sur qui rejoint vos
                  activités, garantissant une expérience sûre et agréable pour
                  toutes les personnes impliquées.
                </p>

                <p>
                  Notre interface intuitive vous permet de créer un événement en
                  quelques minutes, avec des options pour personnaliser le prix,
                  le nombre maximum de participants ou encore le lieu.
                </p>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box padding={5}>
                <img src={completed} alt="onyv-phone" width="100%" />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box marginTop={5}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Box padding={5}>
                <img src={testing} alt="onyv-phone" width="100%" />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Box paddingBottom={2}>
                <Typography
                  align="left"
                  color="secondary"
                  variant="h4"
                  component="h2"
                  style={{ color: '#444444' }}
                >
                  Premium gratuit jusqu'au lancement
                </Typography>
              </Box>
              <Typography
                align="left"
                color="secondary"
                variant="body1"
                component="h5"
              >
                <p>
                  La version beta de notre application est entrain d’être
                  testée, pour nous assurer qu'elle offre la meilleure
                  expérience possible à nos clients. Nous aimerions que vous
                  soyez l'un des premiers utilisateurs à nous faire part de vos
                  commentaires afin que nous continuions à développer
                  l’application.
                </p>

                <p>
                  Nous prévoyons lancer l'application Onyv à l'été 2023. En
                  attendant, nous souhaitons vous remercier pour votre soutien
                  en vous offrant tous les paramètres premium jusqu’au
                  lancement.
                </p>

                <p>
                  Les fonctionnalités suivantes sont actuellement en cours de
                  développement:
                  <ul>
                    <li>Filtres pour rechercher des activités par catégorie</li>
                    <li>
                      Possibilité pour les participants de donner leur avis
                    </li>
                    <li>Paiements via TWINT</li>
                  </ul>
                </p>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box marginTop={4} marginBottom={4}>
        <Divider />
      </Box>

      <Box>
        <Box paddingBottom={2}>
          <Typography
            align="center"
            color="secondary"
            variant="h4"
            component="h2"
            style={{ color: '#444444' }}
          >
            Intéressé ?
          </Typography>
        </Box>
        <Typography
          align="center"
          color="secondary"
          variant="body1"
          component="h5"
        >
          Pourquoi attendre ? Rejoignez Onyv aujourd'hui et commencez à
          planifier votre prochain événement en toute confiance et facilité.{' '}
        </Typography>
        <Box textAlign={'center'} marginTop={3} marginBottom={3}>
          <AuthDialogBtn buttonText={"Je veux m'inscrire"} extraLarge={true} />
        </Box>
      </Box>
    </Container>
  );
}

export default BetaContainer;
