import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import ActivityChat from './ActivityChat';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  appBar: {
    backgroundColor: 'white',
  },
}));

function ActivityChatDialog({ handleClose, open, activity }) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog
      maxWidth="xs"
      open={open}
      fullWidth={true}
      onClose={handleClose}
      fullScreen={fullScreen}
    >
      <AppBar className={classes.appBar} position={'sticky'}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {activity.title}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <ActivityChat handleClose={handleClose} activityID={activity.id} />
    </Dialog>
  );
}

export default ActivityChatDialog;
