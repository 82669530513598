import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { debounce, throttle } from 'throttle-debounce';
import { parsePlaceRequest } from '../../common/search';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { getUserLocation } from '../../common/tools';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
  locationPaper: {
    padding: '2px 4px',
    display: 'flex',
  },
  locationInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
}));

const defaultLocation = {
  id: 'default',
  coordinates: {
    lat: 46.9918,
    lng: 6.931,
  },
  name: 'Neuchâtel, Switzerland',
  address: {
    country: 'Switzerland',
    city: 'Neuchâtel',
    countryCode: 'ch',
  },
};

function AutosuggestLocation(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const {
    activity,
    input,
    setCoordinates,
    setAddress,
    setCoordinatesAndAddress,
    searchType,
    presetLocation,
    userLocation,
    canBeEmpty: canBeEmptyOption,
    ...inputProps
  } = props;
  const canBeEmpty = canBeEmptyOption || false;
  const classes = useStyles();
  const [location, setLocation] = React.useState(() => {
    const locationMap = {
      activityCity: defaultLocation,
      myProfile: { ...presetLocation, id: 'profile' },
    };

    return locationMap[searchType];
  });

  const getCachedUserLocation = () => {
    const userLocation = localStorage.getItem('userLocation');
    if (!userLocation) {
      return;
    }
    return JSON.parse(userLocation);
  };

  useEffect(() => {
    const doFetch = async () => {
      const cachedUserLocation = getCachedUserLocation();
      if (cachedUserLocation) {
        setCoordinates(cachedUserLocation.coordinates);
        setLocation({ ...cachedUserLocation, id: 'fetched' });
      } else if (userLocation) {
        setCoordinates(userLocation.coordinates);
        setLocation({ ...userLocation, id: 'profile' });
      } else {
        const userLocation = (await getUserLocation) || defaultLocation;
        setCoordinates(userLocation.coordinates);
        setLocation(userLocation);
      }
    };

    if (searchType === 'activityCity') {
      doFetch();
    }
  }, [searchType, setCoordinates, userLocation]);

  const searchPlace = (value) => {
    const cityOnly = ['activityCity', 'myProfile', 'city'].includes(searchType);

    parsePlaceRequest(value, cityOnly ? 'city' : 'street').then((res) => {
      if (res) {
        const parsedResults = res.data.features.map((result) => {
          // mapbox returns different result for cities only
          if (cityOnly) {
            const splitPlaceName = result.place_name.split(', ');
            const city = splitPlaceName[0];
            // const region = splitPlaceName[1];
            const country = splitPlaceName[2];

            return {
              id: result.id,
              name: [city, country].join(', '),
              coordinates: {
                lng: result.geometry.coordinates[0],
                lat: result.geometry.coordinates[1],
              },
              address: {
                countryCode: 'ch',
                country,
                city,
              },
            };
          } else {
            const [street, cityAndPostcode, country] =
              result.place_name.split(', ');
            const [postcode, city] = cityAndPostcode.split(' ');

            return {
              id: result.id,
              name: result.place_name,
              coordinates: {
                lng: result.geometry.coordinates[0],
                lat: result.geometry.coordinates[1],
              },
              address: {
                countryCode: 'ch',
                country,
                city,
                street,
                postcode,
              },
            };
          }
        });

        setOptions(parsedResults);
      }
    });
  };

  const editActivityLocation =
    activity !== 'empty'
      ? {
          id: 'default',
          coordinates: activity.coordinates,
          name: [
            activity.location.street,
            activity.location.city,
            activity.location.country,
          ]
            .filter((a) => a)
            .join(', '),
          address: {
            street: activity.location.street,
            city: activity.location.city,
            country: activity.location.country,
            countryCode: activity.location.countryCode,
          },
        }
      : undefined;

  const onValueChange = (value) => {
    if (!value && !canBeEmpty) {
      return;
    }
    setLocation(value);

    if (searchType === 'activityCity') {
      localStorage.setItem('userLocation', JSON.stringify(value));
    }

    if (setCoordinates) {
      setCoordinates(value && value.coordinates);
    }

    if (setAddress) {
      setAddress(value && value.address);
    }

    if (setCoordinatesAndAddress) {
      if (!value) {
        setCoordinatesAndAddress(null);
      } else {
        const name = [
          value.address.street,
          value.address.city,
          value.address.country,
        ]
          .filter((a) => a)
          .join(', ');
        setCoordinatesAndAddress({
          name,
          coordinates: value.coordinates,
          address: value.address,
        });
      }
    }
  };

  const throttleSearchPlace = throttle(500, searchPlace);
  const debounceSearchPlace = debounce(500, searchPlace);

  const onChangeHandle = (value) => {
    if (value === null || value === '') {
      return;
    }
    if (value.length < 5) {
      throttleSearchPlace(value);
    } else {
      debounceSearchPlace(value);
    }
  };

  const defaultInput = (params) => {
    return (
      <TextField
        label="Location"
        {...inputProps}
        {...params}
        variant="outlined"
        onChange={(e) => onChangeHandle(e.target.value)}
        InputProps={{
          ...params.InputProps,
        }}
      />
    );
  };

  const activityInput = (params) => {
    return (
      <Paper
        elevation={0}
        ref={params.InputProps.ref}
        className={classes.locationPaper}
      >
        <IconButton type="submit" aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
          placeholder={t('search.find_activities')}
          inputProps={{ ...params.inputProps }}
          className={classes.locationInput}
          fullWidth={true}
          onChange={(e) => onChangeHandle(e.target.value)}
        />
      </Paper>
    );
  };

  return (
    <>
      <Autocomplete
        id="autocomplete-location"
        open={open}
        clearOnBlur={true}
        onOpen={(e) => setOpen(true)}
        onClose={() => setOpen(false)}
        noOptionsText={t('search.no_results_found')}
        defaultValue={editActivityLocation}
        value={location}
        onChange={(_e, v) => onValueChange(v)}
        getOptionLabel={(option) => option.name || ''}
        options={options}
        autoHighlight={true}
        renderInput={(params) =>
          searchType === 'activityCity'
            ? activityInput(params)
            : defaultInput(params)
        }
      />
      {!searchType && (
        <Box mx={1.5}>
          <FormHelperText>{t('activity_form.address_hint')}</FormHelperText>
        </Box>
      )}
    </>
  );
}

export default AutosuggestLocation;
