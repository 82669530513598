// TODO
// add create activity button form with prefilled info from recurring activity
// add about organizer section

import React, { useEffect, useState } from 'react';
import { Chip, Typography } from '@material-ui/core';
import firebase from '../../config/firebase';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import ReportIssueButton from '../report-issue/ReportIssueButton';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import HelmetMeta from '../meta/HelmetMeta';
import { useTranslation } from 'react-i18next';
import { baseURL, translatedRoutes } from '../../common/routes';
import ShareActivityMenuButton from '../share-activity/ShareActivityMenuButton';
import Hidden from '@material-ui/core/Hidden';
import ParticipantDetails from '../../participant-detail/ParticipantDetails';
import RecurringActivityDialog from '../recurring-activity-dialog/RecurringActivityDialog';
import OrganizeRecurringActivityDialog from './OrganizeRecurringActivityDialog';
import UpcomingActivityCard from '../../organizer-detail/UpcomingActivityCard';
import Map from '../map/Map';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AllActivitiesDialog from './AllActivitiesDialog';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'relative',
    width: 300,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    outline: 0, // remove blue outline
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  fbButton: {
    background: '#3C5898',
    color: 'white',
    height: 45,
    width: 200,
  },
  whButton: {
    background: '#25D366',
    color: 'white',
    height: 45,
    width: 200,
  },
  mailButton: {
    height: 45,
    width: 200,
  },
  delActivity: {
    background: '#FA4353',
    color: 'white',
    height: 45,
    width: 200,
  },
}));

function RecurringActivityDetail({ props, auth }) {
  const [loading, setLoading] = React.useState(true);
  const [activity, setActivity] = React.useState();
  const [isOrganizer, setIsOrganizer] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [openActivityModal, setOpenActivityModal] = React.useState(false);
  const [openAllActivities, setOpenAllActivities] = React.useState(false);
  const [openParticipant, setOpenParticipant] = React.useState(false);
  const [selectedParticipant, setSelectedParticipant] = React.useState();
  const [updatedFormActivity, updateFormActivity] = useState();
  const [createdSingleActivity, createSingleActivity] = useState();
  const [activities, setActivities] = React.useState([]);
  const redirID = props.match.params.id;
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const processSnapshot = (activity, id) => {
    activity.id = id;
    setActivity(activity);
    setLoading(false);
  };

  const handleCloseAllActivities = () => {
    setOpenAllActivities(false);
  };

  const handleOpenAllActivities = () => {
    setOpenAllActivities(true);
  };

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
  };
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleOpenActivityModal = () => {
    setOpenActivityModal(true);
  };
  const handleCloseActivityModal = () => {
    setOpenActivityModal(false);
  };

  const handleOrganizerInfos = () => {
    const organizer = {
      photoURL: activity.organizerPhotoURL,
      publicName: activity.organizerName,
      aboutMe: activity.aboutOrganizer,
    };
    setSelectedParticipant(organizer);
    setOpenParticipant(true);
  };

  const handleDetailsClose = () => {
    setOpenParticipant(false);
  };

  useEffect(() => {
    firebase
      .firestore()
      .collection('recurring-activities')
      .doc(redirID)
      .get()
      .then(function (querySnapshot) {
        if (querySnapshot.data() !== undefined) {
          processSnapshot(querySnapshot.data(), querySnapshot.id);
          setIsOrganizer(true);
          setLoading(false);
        }
      })
      .catch(function (_error) {
        firebase
          .firestore()
          .collection('public-recurring-activities')
          .doc(redirID)
          .get()
          .then(function (querySnapshot) {
            if (querySnapshot.data() !== undefined) {
              processSnapshot(querySnapshot.data(), querySnapshot.id);
              setIsOrganizer(false);
              setLoading(false);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
  }, [updatedFormActivity, auth, redirID]);

  useEffect(() => {
    const publicActivities = [];
    const activityQueryPromises = [];

    activityQueryPromises.push(
      firebase
        .firestore()
        .collection('public-activities')
        .where('recurringActivityID', '==', redirID)
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs.forEach((doc) => {
            if (doc.data().deleted) {
              return;
            }

            const activity = {
              ...doc.data(),
              id: doc.id,
              startTime: doc.data().startDate.seconds * 1000,
              endTime: doc.data().endDate.seconds * 1000,
            };

            publicActivities.push(activity);
          });
        })
    );
    Promise.all(activityQueryPromises).then(() => {
      setActivities(publicActivities.sort((a, b) => a.startTime - b.startTime));
      // setLoading(false);
    });
  }, [createdSingleActivity, redirID]);

  if (loading) {
    return (
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else {
    const activityUrl =
      baseURL +
      translatedRoutes[i18n.language].activityDetailWithoutId +
      '/' +
      activity.id;

    return (
      <div>
        <HelmetMeta
          title={activity.title}
          url={activityUrl}
          description={activity.publicDescription}
          image={activity.activityImageURL}
        />
        <Box
          maxWidth={800}
          mt={{ xs: 0, sm: 5, md: 5, lg: 5, xl: 5 }}
          px={{ sm: '2%', xs: 0 }}
          mx="auto"
        >
          <AppBar
            position="static"
            color="transparent"
            borderRadius={12}
            elevation={1}
          >
            <Toolbar>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                wrap={{ sm: 'nowrap', xs: 'wrap' }}
              >
                <Hidden xsDown>
                  <IconButton
                    align="left"
                    color="inherit"
                    aria-label="menu"
                    component={Link}
                    to="/"
                  >
                    <ArrowBackIosRoundedIcon color="secondary" />
                  </IconButton>
                </Hidden>
                <Typography
                  color="primary"
                  variant="h5"
                  component="h2"
                  align="left"
                >
                  {activity.title}
                </Typography>
                <Box>
                  {isOrganizer && (
                    <IconButton
                      align="right"
                      color="inherit"
                      aria-label="menu"
                      onClick={handleOpenEditModal}
                    >
                      <EditRoundedIcon color="secondary" />
                    </IconButton>
                  )}
                  <ShareActivityMenuButton activity={activity} />
                </Box>
              </Grid>
            </Toolbar>
          </AppBar>
          <Paper>
            <CardMedia
              component="img" //will rely in object-fit for image centering
              height="300"
              image={activity.activityImageURL}
            />
            <Box
              mt="2%"
              mx={{ xs: 2, sm: '10%', md: '10%', lg: '10%', xl: '10%' }}
            >
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Grid container direction="column">
                    <Box
                      pt={0.3}
                      display="flex"
                      alignItems="center"
                      justify-content="space-between"
                    >
                      <Chip
                        size={'small'}
                        avatar={
                          <Avatar
                            alt={activity.organizerName} //initials will be displayed if no pic
                            src={activity.organizerPhotoURL}
                            className={classes.small}
                          />
                        }
                        label={activity.organizerName}
                        onClick={handleOrganizerInfos}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Box display="flex" alignItems="center">
                      <LocationOnIcon color="secondary" />
                      <Box ml={0.4}>
                        <Typography
                          noWrap
                          color="secondary"
                          variant="body2"
                          component="p"
                          align="left"
                        >
                          {activity.location.city !== null
                            ? activity.location.city
                            : 'not found'}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            {isOrganizer && (
              <Box display="flex" justifyContent="center" mt={3} pb={1}>
                <Button
                  color="primary"
                  variant="contained"
                  size="medium"
                  onClick={handleOpenActivityModal}
                  startIcon={<AddIcon />}
                >
                  {t('recurring_activity.create_single_event')}
                </Button>
              </Box>
            )}
            <Box mt={3} px="2%">
              <Typography
                noWrap
                align="left"
                color="primary"
                variant="h5"
                component="h2"
              >
                {t('activity_detail.public_info')}
              </Typography>
            </Box>
            <Box mt={2} px="2%">
              <Typography
                color="secondary"
                variant="body2"
                component="p"
                align="left"
              >
                {activity.publicDescription || activity.description}
              </Typography>
            </Box>

            {activities.length > 0 && (
              <>
                <Box mt={3} px="2%">
                  <Typography
                    noWrap
                    align="left"
                    color="primary"
                    variant="h5"
                    component="h2"
                  >
                    Prochains rendez-vous
                  </Typography>
                </Box>
                <Box mt={2} px="2%">
                  {/*only display first 4 upcoming events*/}
                  {activities.slice(0, 4).map((activity) => {
                    return (
                      <UpcomingActivityCard
                        activity={activity}
                        key={activity.id}
                      />
                    );
                  })}
                </Box>
                {activities.length > 4 && (
                  <Box display="flex" justifyContent="center" mt={3} pb={1}>
                    <Button
                      color="primary"
                      variant="contained"
                      size="medium"
                      onClick={handleOpenAllActivities}
                      startIcon={<VisibilityIcon />}
                    >
                      {t('recurring_activity.show_more')}
                    </Button>
                  </Box>
                )}
              </>
            )}

            {isOrganizer && (
              <>
                <Box mt={3} px="2%">
                  <Typography
                    noWrap
                    align="left"
                    color="primary"
                    variant="h5"
                    component="h2"
                  >
                    {t('activity_detail.private_info')}
                  </Typography>
                </Box>
                <Box mt={2} px="2%">
                  <Typography
                    color="secondary"
                    variant="body2"
                    component="p"
                    align="left"
                  >
                    {activity.privateDescription}
                  </Typography>
                </Box>
                <Box mt={3} px="2%">
                  <Typography
                    noWrap
                    align="left"
                    color="primary"
                    variant="h5"
                    component="h2"
                  >
                    {t('activity_detail.location')}
                  </Typography>
                </Box>
                <Box px="2%" mt={3}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Map
                      lat={activity.coordinates.latitude}
                      lng={activity.coordinates.longitude}
                    />
                    <Box ml={3} mt={3}>
                      <Typography
                        variant="subtitle2"
                        component="p"
                        color="secondary"
                      >
                        {[
                          activity.location.street,
                          activity.location.city,
                          activity.location.country,
                        ]
                          .filter((e) => e)
                          .join(', ')}
                      </Typography>
                    </Box>
                  </Grid>
                </Box>
              </>
            )}

            {activity.aboutOrganizer.length > 0 && (
              <>
                <Box mt={2} px="2%">
                  <Typography
                    color="primary"
                    variant="h5"
                    component="h2"
                    align="left"
                    noWrap
                  >
                    {t('activity_detail.about')} {activity.organizerName}
                  </Typography>
                </Box>
                <Box mt={2} pb={2} px="2%">
                  <Typography
                    color="secondary"
                    variant="body2"
                    component="p"
                    align="left"
                  >
                    {activity.aboutOrganizer}
                  </Typography>
                </Box>
              </>
            )}

            <Box display="flex" justifyContent="center" mt={3} pb={1}>
              <Button
                color="primary"
                variant="contained"
                size="medium"
                component={Link}
                to="/"
              >
                {t('activity_detail.activity_list_btn')}
              </Button>
            </Box>
            <Box display="flex" justifyContent="center" pb={1}>
              <ReportIssueButton />
            </Box>
          </Paper>
          <RecurringActivityDialog
            open={openEditModal}
            handleClose={handleCloseEditModal}
            activity={activity}
            updateActivity={updateFormActivity}
          />
          <ParticipantDetails
            open={openParticipant}
            handleClose={handleDetailsClose}
            participant={selectedParticipant}
          />
          <OrganizeRecurringActivityDialog
            open={openActivityModal}
            activity={activity}
            handleClose={handleCloseActivityModal}
            createActivityTrigger={createSingleActivity}
          />
          <AllActivitiesDialog
            activities={activities}
            open={openAllActivities}
            handleClose={handleCloseAllActivities}
          />
        </Box>
      </div>
    );
  }
}

export default RecurringActivityDetail;
