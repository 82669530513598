import React, { useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useForm } from 'react-hook-form';
import AutosuggestLocation from '../autosuggest-location/AutosuggestLocation';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import * as dayJs from 'dayjs';
import { createActivity as createActivityService } from '../../common/firestoreService';
import firebase from '../../config/firebase';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { v4 as uuid } from 'uuid';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { scrambleCoordinate } from '../../common/tools';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { translatedRoutes } from '../../common/routes';
import RandomImagePicker from './RandomImagePicker';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  media: {
    height: 140,
  },
}));

function ActivityForm({
  activity,
  editActivity,
  duplicate,
  updateActivity,
  handleClose,
}) {
  const classes = useStyles();
  const { register, handleSubmit, errors, formState } = useForm();
  const { t, i18n } = useTranslation();
  const initDate =
    editActivity && activity.startTime
      ? dayjs(activity.startTime)
      : dayJs().hour(20).minute(0).second(0).millisecond(0).add(1, 'day');
  const initcoordinates =
    editActivity && activity.coordinates
      ? {
          lat: activity.coordinates.latitude,
          lng: activity.coordinates.longitude,
        }
      : null;
  const [selectedDate, setSelectedDate] = useState(initDate);
  const [startTime, setStartTime] = useState(initDate);
  const [activityImageURL, setActivityImageURL] = useState(
    editActivity || duplicate ? activity.activityImageURL : null
  );
  const [endTime, endTimeChange] = useState(
    editActivity && activity.endTime
      ? dayjs(activity.endTime)
      : initDate.add(1, 'hour')
  );
  const [coordinates, setCoordinates] = useState(initcoordinates);
  const [location, setLocation] = useState(
    editActivity && activity.location ? activity.location : null
  );
  const [uploadingImage, setUploadingImage] = useState(false);
  const organizerID = useSelector((state) => state.firebase.auth.uid);
  const profile = useSelector((state) => state.firebase.profile);
  const history = useHistory();
  const onDrop = useCallback(async (acceptedFiles) => {
    setUploadingImage(true);
    const image = acceptedFiles[0];
    const extension = image.name.split('.').pop();
    const storageRef = firebase.storage().ref();
    const newImage = storageRef.child(`activities/${uuid()}.${extension}`);
    await newImage.put(image);
    const imageUrl = await newImage.getDownloadURL();
    setActivityImageURL(imageUrl);
    setUploadingImage(false);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'image/jpeg, image/png',
  });

  const createActivity = async (formValues) => {
    const date = selectedDate.second(0).millisecond(0);
    const startDate = date
      .hour(startTime.hour())
      .minute(startTime.minute())
      .toDate();
    const endDate = date.hour(endTime.hour()).minute(endTime.minute()).toDate();
    const { title, privateDescription, publicDescription } = formValues;
    const newActivity = {
      startDate: firebase.firestore.Timestamp.fromDate(startDate),
      endDate: firebase.firestore.Timestamp.fromDate(endDate),
      coordinates: new firebase.firestore.GeoPoint(
        coordinates.lat,
        coordinates.lng
      ),
      scrambledCoordinates: new firebase.firestore.GeoPoint(
        scrambleCoordinate(coordinates.lat),
        scrambleCoordinate(coordinates.lng)
      ),
      publicDescription,
      privateDescription,
      title,
      organizerID,
      organizerName: profile.publicName,
      organizerPhotoURL: profile.photoURL,
      aboutOrganizer: profile.aboutMe,
      activityImageURL,
      location,
    };
    try {
      await createActivityService(
        newActivity,
        editActivity || duplicate ? (duplicate ? null : activity.id) : null
      ).then(function (createdActivity) {
        handleClose();
        if (updateActivity) {
          updateActivity(newActivity);
        }
        if (duplicate || !editActivity) {
          history.push(
            `${translatedRoutes[i18n.language].activityDetailWithoutId}/${
              createdActivity.id
            }`
          );
        }
      });
    } catch (firebaseError) {
      console.log(firebaseError);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} onSubmit={handleSubmit(createActivity)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                defaultValue={editActivity || duplicate ? activity.title : null}
                id="title"
                label={t('activity_form.title')}
                name="title"
                inputRef={register({
                  required: true,
                  minLength: {
                    value: 8,
                    message: t('error.title_too_short'),
                  },
                })}
                inputProps={{ maxLength: 80 }}
                error={!!errors.title}
                helperText={errors.title && errors.title.message}
              />
            </Grid>

            <Grid item xs={12}>
              <DatePicker
                cancelLabel={t('common.cancel')}
                autoOk
                label={t('activity_form.date')}
                value={selectedDate}
                onChange={setSelectedDate}
                disablePast
                disableToolbar
                fullWidth
                required
                inputVariant="outlined"
                name="date"
                inputRef={register({ required: true, minLength: 2 })}
                error={!!errors.date}
                helperText={errors.date && errors.date.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TimePicker
                autoOk
                ampm={false}
                label={t('activity_form.start_time')}
                value={startTime}
                minutesStep={5}
                onChange={setStartTime}
                fullWidth
                required
                inputVariant="outlined"
                name="startTime"
                inputRef={register({ required: true })}
                error={!!errors.startTime}
                helperText={errors.startTime && errors.startTime.message}
                cancelLabel={t('common.cancel')}
              />
            </Grid>

            <Grid item xs={12}>
              <TimePicker
                autoOk
                ampm={false}
                label={t('activity_form.end_time')}
                value={endTime}
                minutesStep={5}
                onChange={endTimeChange}
                fullWidth
                required
                inputVariant="outlined"
                name="endTime"
                inputRef={register({
                  required: true,
                  validate: () => {
                    if (startTime.isAfter(endTime)) {
                      return t('error.end_time_should_come_after_start_time');
                    } else if (endTime.diff(startTime, 'minute') < 15) {
                      return t('error.activity_should_take_min_15');
                    }
                  },
                })}
                error={!!errors.endTime}
                helperText={errors.endTime && errors.endTime.message}
                cancelLabel={t('common.cancel')}
              />
            </Grid>

            <Grid item xs={12}>
              <AutosuggestLocation
                activity={activity}
                setCoordinates={setCoordinates}
                setAddress={setLocation}
                name="location"
                required
                inputRef={register({ required: true })}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="public-description"
                label={t('activity_form.public_description')}
                defaultValue={
                  editActivity || duplicate ? activity.publicDescription : null
                }
                multiline
                maxRows={10}
                variant="outlined"
                fullWidth
                required
                inputRef={register({
                  required: true,
                  minLength: {
                    value: 8,
                    message: t('error.description_too_short'),
                  },
                })}
                name="publicDescription"
                inputProps={{ maxLength: 1000 }}
                error={!!errors.publicDescription}
                helperText={
                  (errors.publicDescription &&
                    errors.publicDescription.message) ||
                  t('activity_form.public_description_hint')
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="private-description"
                label={t('activity_form.private_description')}
                defaultValue={
                  editActivity || duplicate ? activity.privateDescription : null
                }
                multiline
                maxRows={10}
                variant="outlined"
                fullWidth
                required
                inputRef={register({
                  required: true,
                  minLength: {
                    value: 8,
                    message: t('error.description_too_short'),
                  },
                })}
                name="privateDescription"
                inputProps={{ maxLength: 2000 }}
                error={!!errors.privateDescription}
                helperText={
                  (errors.privateDescription &&
                    errors.privateDescription.message) ||
                  t('activity_form.private_description_hint')
                }
              />
            </Grid>

            <Grid item xs={12} hidden={uploadingImage || activityImageURL}>
              <RandomImagePicker setActivityImageURL={setActivityImageURL} />
              <Box display="flex" justifyContent="center" pt={2}>
                <FormHelperText>
                  {t('activity_form.choose_photo_hint')}
                </FormHelperText>
              </Box>
            </Grid>

            <Grid item xs={12} {...getRootProps()}>
              {uploadingImage && (
                <>
                  <Grid container justifyContent="center">
                    <Box m={2}>
                      <CircularProgress />
                    </Box>
                  </Grid>
                </>
              )}
              {!uploadingImage && !activityImageURL && (
                <>
                  <Box mb={-1.5}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      size="large"
                      className={classes.button}
                      startIcon={<CloudUploadIcon />}
                    >
                      {t('activity_form.choose_photo')}
                      <input {...getInputProps()} />
                    </Button>
                  </Box>
                </>
              )}
              {!uploadingImage && activityImageURL && (
                <>
                  <Card>
                    <CardHeader
                      className={classes.cardHeader}
                      title={t('meta.activity_image')}
                      action={
                        <IconButton
                          aria-label="settings"
                          onClick={() => setActivityImageURL(null)}
                        >
                          <CloseIcon />
                        </IconButton>
                      }
                      titleTypographyProps={{ variant: 'subtitle1' }}
                    />
                    <CardMedia
                      className={classes.media}
                      image={activityImageURL}
                      title={t('meta.activity_image')}
                    />
                  </Card>
                </>
              )}
            </Grid>
          </Grid>
          <Box mb={1}>
            <Button
              size="large"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={formState.isSubmitting ? true : !activityImageURL}
            >
              {t('common.lets_go')}
            </Button>
          </Box>
        </form>
      </div>
    </Container>
  );
}
export default ActivityForm;
