import React from 'react';
import Button from '@material-ui/core/Button';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import AuthDialog from './AuthDialog';

function AuthDialogBtn({ buttonText, extraLarge }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const onMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDialog = () => {
    setOpen(!open);
  };

  return (
    <span>
      <Button
        variant="contained"
        color="primary"
        onClick={toggleDialog}
        style={{
          width: extraLarge ? '300px' : undefined,
          height: extraLarge ? '50px' : undefined,
        }}
      >
        {!buttonText && onMobile && <AccountCircleOutlinedIcon />}
        {!buttonText && !onMobile && t('authentication.log_in')}
        {buttonText && buttonText}
      </Button>
      <AuthDialog handleClose={toggleDialog} open={open} />
    </span>
  );
}

export default AuthDialogBtn;
