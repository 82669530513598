import React, { Fragment } from 'react';
import ReportIssueDialog from './ReportIssueDialog';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';
import FeedbackIcon from '@material-ui/icons/Feedback';
import { useTranslation } from 'react-i18next';

function ReportIssueButton() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();

  return (
    <Fragment>
      <div onClick={() => handleOpen()} style={{ cursor: 'pointer' }}>
        <Box display="flex" justify-content="space-between">
          <Box mr={1}>
            <FormHelperText>
              <FeedbackIcon style={{ fontSize: '1rem' }} />
            </FormHelperText>
          </Box>
          <Box>
            <FormHelperText style={{ cursor: 'pointer', textAlign: 'center' }}>
              {t('report_issue.report_issue')}
            </FormHelperText>
          </Box>
        </Box>
      </div>
      <ReportIssueDialog open={open} handleClose={handleClose} />
    </Fragment>
  );
}

export default ReportIssueButton;
