import React from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ActivityDialog from './ActivityDialog';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme, useMediaQuery } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import LoopIcon from '@mui/icons-material/Loop';
import RecurringActivityDialog from '../recurring-activity-dialog/RecurringActivityDialog';

const useStyles = makeStyles((theme) => ({
  stack: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    display: 'grid',
  },
  typo: {
    variant: 'h6',
  },
  addIcon: {
    marginRight: theme.spacing(1),
  },
  recurringFab: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'whitesmoke',
    },
  },
}));

const theme = createMuiTheme({
  typography: {
    body1: {
      color: 'white',
      fontWeight: 450,
    },
  },
});

function ActivityDialogButton() {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const onMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openRecurring, setRecurringOpen] = React.useState(false);

  const handleRecurringOpen = () => {
    setRecurringOpen(true);
  };

  const handleRecurringClose = () => {
    setRecurringOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.stack}>
      <Box pb={2}>
        <Fab
          color="primary"
          className={classes.recurringFab}
          aria-label="add-event"
          onClick={handleRecurringOpen}
          variant={onMobile ? 'round' : 'extended'}
          // color="inherit"
        >
          <LoopIcon style={{ fill: '#44fb8c' }} />

          <ThemeProvider theme={theme}>
            <Hidden xsDown>
              <Box ml={1}>
                <Typography style={{ color: '#44fb8c' }} color={'#44fb8c'}>
                  {t('floating_menu.create_recurring_activity')}
                </Typography>
              </Box>
            </Hidden>
          </ThemeProvider>
        </Fab>
      </Box>
      <Fab
        color="primary"
        aria-label="add-recurring-event"
        className={classes.fab}
        onClick={handleOpen}
        variant={onMobile ? 'round' : 'extended'}
      >
        <AddIcon style={{ fill: 'white' }} />

        <ThemeProvider theme={theme}>
          <Hidden xsDown>
            <Box ml={1}>
              <Typography>{t('floating_menu.organise_activity')}</Typography>
            </Box>
          </Hidden>
        </ThemeProvider>
      </Fab>
      <ActivityDialog open={open} handleClose={handleClose} activity="empty" />
      <RecurringActivityDialog
        open={openRecurring}
        handleClose={handleRecurringClose}
        activity="empty"
      />
    </div>
  );
}

export default ActivityDialogButton;
