import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import OrganizeActivityButton from '../my-activities/OrganizeActivityButton';
import AuthDialogBtn from '../auth-dialog/AuthDialogBtn';
import { useSelector } from 'react-redux';

function NoMoreActivities() {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.firebase.auth);

  return (
    <Box mx="auto" mt="auto" minwidth={380} maxWidth={600} p={3}>
      <Box mt={8} mb={4} style={{textAlign: 'center'}}>
        <Typography variant="h4">
          {t('call_to_action.no_more_activities')}
        </Typography>
        <Box pt={4} textAlign={'center'}>
          {auth.isLoaded && !auth.isEmpty && <OrganizeActivityButton />}
          {auth.isLoaded && auth.isEmpty && (
            <AuthDialogBtn buttonText={ t('activity_form.organize') } />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default NoMoreActivities;
