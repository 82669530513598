export const capitalizeFirstLetter = (string) => {
  return string[0].toUpperCase() + string.slice(1);
};

const randomInteger = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

// returns lat or lng coordinates with a random variation of max 500m
export const scrambleCoordinate = (latOrLng) => {
  const degreesPerMeter = 0.000009; // very rough estimation
  const change = randomInteger(-250, 250) * degreesPerMeter;

  return latOrLng + change;
};

export const getUserLocation = fetch('https://geolocation-db.com/json/')
  .then((location) => location.json())
  .then((location) => {
    if (!location || !location.city || !location.country) {
      return;
    }

    return {
      name: `${location.city}, ${location.country_name}`,
      address: {
        country: location.country_name,
        city: location.city,
        countryCode: location.country_code.toLowerCase(),
      },
      coordinates: {
        lat: location.latitude,
        lng: location.longitude,
      },
    };
  })
  .catch(() => null); // make sure that when browser blocks this request it doesn't blow up
