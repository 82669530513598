import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import ActivityDialog from '../activity-dialog/ActivityDialog';
import RecurringActivityDialog from '../recurring-activity-dialog/RecurringActivityDialog';
import { Chip } from '@material-ui/core';
import LoopIcon from '@mui/icons-material/Loop';
import Box from '@material-ui/core/Box';
// import IconButton from '@material-ui/core/';

function OrganizeActivityButton() {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [openRecurring, setRecurringOpen] = React.useState(false);

  const handleRecurringOpen = () => {
    setRecurringOpen(true);
  };

  const handleRecurringClose = () => {
    setRecurringOpen(false);
  };

  return (
    <div>
      <Box pb={1}>
        <Button onClick={handleOpen} variant="contained" color="primary">
          {t('activity_form.organize')}
        </Button>
      </Box>
      <Chip
        size={'small'}
        icon={<LoopIcon />}
        label={t('floating_menu.create_recurring_activity')}
        onClick={handleRecurringOpen}
      />
      <ActivityDialog open={open} handleClose={handleClose} activity="empty" />
      <RecurringActivityDialog
        open={openRecurring}
        handleClose={handleRecurringClose}
        activity="empty"
      />
    </div>
  );
}

export default OrganizeActivityButton;
