import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translatedRoutes } from '../../common/routes';

const useStyles = makeStyles({
  primaryHorizGrid: {
    direction: 'row',
    justify: 'space-evenly',
    alignItems: 'center',
    spacing: 5,
  },
  card: {
    borderRadius: 19,
    border: '0.25px solid #DCDCDC',
    boxShadow: 'none',
  },
});

function ActivityCard(props) {
  const recurringActivity = !props.activity.startTime;
  const startTime = dayjs(props.activity.startTime);
  const endTime = dayjs(props.activity.endTime);
  const classes = useStyles();
  const { i18n } = useTranslation();
  const history = useHistory();
  const handleClick = () =>
    history.push(
      `${
        recurringActivity
          ? translatedRoutes[i18n.language].recurringActivityDetailWithoutId
          : translatedRoutes[i18n.language].activityDetailWithoutId
      }/${props.activity.id}`
    );

  return (
    <Card className={classes.card}>
      <CardActionArea onClick={handleClick}>
        <CardMedia
          component="img" //will rely in object-fit for image centering D
          height="300"
          image={props.activity.activityImageURL}
        />
        <CardContent>
          <Grid container className={classes.primaryHorizGrid}>
            <Grid item xs zeroMinWidth>
              <Grid container direction="column" spacing={0}>
                <Grid item xs zeroMinWidth>
                  <Box mt={-1}>
                    <Typography
                      noWrap
                      align="left"
                      color="primary"
                      variant="h5"
                      component="h2"
                    >
                      {props.activity.title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Box mt={-0.5}>
                    <Typography
                      noWrap
                      align="left"
                      variant="body2"
                      color="secondary"
                      component="p"
                    >
                      {props.activity.location.city}{' '}
                      <small>
                        {props.activity.kmFromUser === undefined
                          ? ''
                          : `${props.activity.kmFromUser} km`}
                      </small>
                    </Typography>
                  </Box>
                </Grid>
                {!recurringActivity && (
                  <Grid item xs zeroMinWidth>
                    <Box
                      display="flex"
                      justify-content="space-between"
                      mt={1}
                      mb={0}
                    >
                      <TodayRoundedIcon color="secondary" fontSize="small" />
                      <Box mx={0.4}>
                        <Typography
                          noWrap
                          color="secondary"
                          variant="body2"
                          component="p"
                          align="left"
                        >
                          {startTime.format('DD/MM/YY')}
                        </Typography>
                      </Box>
                      <Box ml={2}>
                        <ScheduleRoundedIcon
                          color="secondary"
                          fontSize="small"
                        />
                      </Box>
                      <Box mx={0.4}>
                        <Typography
                          noWrap
                          color="secondary"
                          variant="body2"
                          component="p"
                          align="left"
                        >
                          {startTime.format('HH:mm')} -{' '}
                          {endTime.format('HH:mm')}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={false} zeroMinWidth>
              <Box mt={0}>
                <Box display="flex" justifyContent="center">
                  <Avatar
                    alt={props.activity.organizerName} //initials will be displayed if no pic
                    src={props.activity.organizerPhotoURL}
                    className={classes.small}
                  />
                </Box>
                <Typography
                  noWrap
                  color="secondary"
                  variant="body2"
                  component="p"
                  align="center"
                >
                  {props.activity.organizerName}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default ActivityCard;
