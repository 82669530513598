import React from 'react';
import ShareIcon from '@material-ui/icons/Share';
import ShareActivityDialog from './ShareActivityDialog';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

function ShareActivityButton({activity}) {
  const initiallyOpened = !!(new URLSearchParams(window.location.search)).get('shareopened');
  const [showModal, setShowModal] = React.useState(initiallyOpened);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const { t } = useTranslation();

  return(
    <>
      <Button
        color="primary"
        variant="contained"
        size="medium"
        onClick={openModal}
        startIcon={<ShareIcon />}
      >
        {t('activity_detail.share_btn')}
      </Button>
      <ShareActivityDialog closeModal={closeModal} open={showModal} activity={activity} />
    </>
  )
}
export default ShareActivityButton;
