import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CustomActivityForm from './CustomActivityForm';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

function CustomActivityDialog({ prefilled }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(!open)

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      aria-labelledby="form-dialog-title"
    >
      <AppBar className={classes.appBar} color="transparent">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {t('activity_form.organize')}
          </Typography>
        </Toolbar>
      </AppBar>
      <CustomActivityForm
        handleClose={handleOpen}
        prefilled={prefilled}
      />
    </Dialog>
  );
}

export default CustomActivityDialog;
