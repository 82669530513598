import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ApproveRequestFormRow } from './ApproveRequestFormRow';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    overflow: 'hidden'
  },
  approveActive: {
    color: '#4fe8ab',
    '&:hover': {
      color: '#4fe8ab',
    },
  },
  approveInactive: {
    '&:hover': {
      color: '#4fe8ab',
    },
  },
  denyActive: {
    color: '#e8664f',
    '&:hover': {
      color: '#e8664f',
    },
  },
  denyInactive: {
    '&:hover': {
      color: '#e8664f',
    },
  },
  grid: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

function ApproveRequestsForm({
  isOrganizer,
  joinRequests,
  increaseParticipantCount,
  decreaseParticipantCount,
  handleDetailsClick,
                               setParticipants,
  participants
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {joinRequests.map((joinRequest) => (
        <ApproveRequestFormRow
          isOrganizer={isOrganizer}
          key={joinRequest.id}
          joinRequest={joinRequest}
          increaseParticipantCount={increaseParticipantCount}
          decreaseParticipantCount={decreaseParticipantCount}
          handleDetailsClick={handleDetailsClick}
          setParticipants={setParticipants}
          participants={participants}
        />
      ))}
    </div>
  );
}

export default ApproveRequestsForm;
