import React from 'react';
import { Helmet } from 'react-helmet';
import onyv from '../layout/onyv_logo.png';

function HelmetMeta(props) {
  HelmetMeta.defaultProps = {
    website: 'onyv.ch',
    url: 'onyv.ch/',
    title: 'onyv',
    quote:
      "Onyv rencontre de nouveaux amis et d'autres passionés et organise des activités !",
    description:
      "trouve une activité qui te passionne et rencontre de nouveaux amis et organisation d'activités",
    image: { onyv },
  };

  return (
    <>
      <Helmet>
        <title>{props.title} | onyv</title>
        <meta charSet="utf-8" />
        <meta property="type" content="website" />
        <meta property="url" content={props.url} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="title" content={props.title} />
        <meta property="quote" content={props.quote} />
        <meta name="description" content={props.description} />
        <meta property="image" content={props.image} />
        <meta property="og:locale" content="fr_fr" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={props.title} />
        <meta property="og:quote" content={props.quote} />
        <meta property="og:hashtag" content="Onyv!" />
        <meta property="og:image" content={props.image} />
        <meta property="og:url" content={props.url} />
        <meta property="og:site_name" content="onyv.ch" />
        <meta property="og:description" content={props.description} />
      </Helmet>
    </>
  );
}

export default HelmetMeta;
