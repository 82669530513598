import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import ReportIssueForm from './ReportIssueForm';

function ReportIssueDialog(props) {
  const { handleClose, open } = props;

  return (
    <Dialog maxWidth="xs" open={open} fullWidth={true} onClose={handleClose}>
      <ReportIssueForm handleClose={handleClose} />
    </Dialog>
  );
}

export default ReportIssueDialog;
