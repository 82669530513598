import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useForm } from 'react-hook-form';
import {
  signInWithFacebook,
  signInWithGoogle,
  signUpWithEmail,
} from '../../common/firebaseService';
import { emailRegex } from '../../common/regexService';
import Divider from '@material-ui/core/Divider';
import FacebookIcon from '@material-ui/icons/Facebook';
import google from '../layout/google_logo.svg';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  facebookBtn: {
    backgroundColor: '#3b5998',
    color: 'white',
  },
  googleBtn: {
    backgroundColor: 'white',
  },
}));

function SignUpForm(props) {
  const { handleClose } = props;
  const classes = useStyles();
  const { register, handleSubmit, errors, setError, watch } = useForm();
  const { t } = useTranslation();
  const signUp = async (formValues) => {
    try {
      await signUpWithEmail(formValues);
      handleClose();
    } catch (firebaseError) {
      setError('email', firebaseError);
    }
  };
  const validatePasswordsMatch = (confirmationPassword) => {
    if (confirmationPassword !== watch('password')) {
      return t('authentication.passwords_dont_match');
    }
  };

  const validateEmail = (email) => {
    if (!email.match(emailRegex)) {
      return t('authentication.invalid_email');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} onSubmit={handleSubmit(signUp)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                className={classes.facebookBtn}
                fullWidth
                startIcon={<FacebookIcon />}
                onClick={() => signInWithFacebook()}
              >
                {t('authentication.fb_log_in_btn')}
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                className={classes.googleBtn}
                fullWidth
                variant="outlined"
                startIcon={
                  <img src={google} alt="google-logo" width={16} height={16} />
                }
                onClick={() => signInWithGoogle()}
              >
                <span style={{color: 'black'}}>{t('authentication.google_log_in_btn')}</span>
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Box p={2}>
                <Divider variant="middle" />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label={t('authentication.email_address')}
                name="email"
                inputRef={register({ required: true, validate: validateEmail })}
                error={!!errors.email}
                helperText={errors.email && errors.email.message}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label={t('authentication.password')}
                type="password"
                autoComplete="new-password"
                id="password"
                inputRef={register({ required: true, min: 8 })}
                error={!!errors.password}
                helperText={errors.password && t('error.password_too_short')}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="confirmationPassword"
                label={t('authentication.confirm_password')}
                type="password"
                autoComplete="confirm-password"
                id="confirmationPassword"
                inputRef={register({
                  required: true,
                  min: 8,
                  validate: validatePasswordsMatch,
                })}
                error={!!errors.confirmationPassword}
                helperText={
                  errors.confirmationPassword &&
                  errors.confirmationPassword.message
                }
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {t('authentication.sign_up')}
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default SignUpForm;
