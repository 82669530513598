import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import { createIssue } from '../../common/firestoreService';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function ReportIssueForm(props) {
  const { handleClose } = props;
  const classes = useStyles();
  const { register, handleSubmit, formState } = useForm({ mode: 'onChange' });
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const { t } = useTranslation();

  const submit = async ({ issue }) => {
    const name =
      [profile.firstName, profile.lastName].filter((e) => e).join(' ') ||
      'Unknown';
    const newIssue = {
      description: issue,
      userID: auth.uid,
      name,
      activeURL: window.location.href,
    };

    try {
      await createIssue(newIssue);
      handleClose();
    } catch (firebaseError) {
      console.log(firebaseError);
    }
  };

  return (
    <Container component="main">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} onSubmit={handleSubmit(submit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="outlined-multiline-static"
                label={t('report_issue.tell_us')}
                maxRows={10}
                multiline
                variant="outlined"
                fullWidth
                inputRef={register({ required: true, minLength: { value: 8 } })}
                name="issue"
                inputProps={{ maxLength: 1000 }}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!formState.isValid || formState.isSubmitting}
          >
            {t('report_issue.send')}
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default ReportIssueForm;
