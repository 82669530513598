import firebase from '../config/firebase';

export const createActivity = (activity, uid) => {
  const firestore = firebase.firestore();
  const newActivity = {
    ...activity,
  };
  return !!uid
    ? firestore.collection('activities').doc(uid).set(newActivity)
    : firestore.collection('activities').add(newActivity);
};

export const createRecurringActivity = (activity, uid) => {
  const firestore = firebase.firestore();
  const recurringActivity = {
    ...activity,
  };
  return uid
    ? firestore
        .collection('recurring-activities')
        .doc(uid)
        .set(recurringActivity)
    : firestore.collection('recurring-activities').add(recurringActivity);
};

export const createUser = (uid, user) => {
  const firestore = firebase.firestore();

  return firestore.collection('users').doc(uid).set(user);
};

export const createIssue = (issue) => {
  const firestore = firebase.firestore();
  const issueWithTimestamp = {
    ...issue,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
  };
  return firestore.collection('issues').add(issueWithTimestamp);
};

export const createJoinActivityRequest = (activityID, userID, request) => {
  const firestore = firebase.firestore();
  const requestWithTimestamp = {
    ...request,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
  };

  return firestore
    .collection('activities')
    .doc(activityID)
    .collection('requests')
    .doc(userID)
    .set(requestWithTimestamp);
};

export const approveJoinRequest = (userID, activityID) => {
  const firestore = firebase.firestore();
  return firestore
    .collection('activities')
    .doc(activityID)
    .collection('requests')
    .doc(userID)
    .set({ status: 'approved' }, { merge: true });
};

export const denyJoinRequest = (userID, activityID) => {
  const firestore = firebase.firestore();
  return firestore
    .collection('activities')
    .doc(activityID)
    .collection('requests')
    .doc(userID)
    .set({ status: 'denied' }, { merge: true });
};

export const recursiveDeleteActivity = (uid) => {
  const path = '/activities/' + uid;
  const deleteFn = firebase.functions().httpsCallable('recursiveDelete');
  return deleteFn({ path: path, uid: uid });
};

export const markPublicActivityAsDeleted = async (activityID) => {
  await firebase
    .firestore()
    .collection('public-activities')
    .doc(activityID)
    .update({ deleted: true });
};

export const sendMessage = (activityID, message) => {
  const firestore = firebase.firestore();

  return firestore
    .collection('activities')
    .doc(activityID)
    .collection('messages')
    .add({
      ...message,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    });
};

export const markNotificationAsRead = (notificationID) => {
  const firestore = firebase.firestore();

  return firestore
    .collection('notifications')
    .doc(notificationID)
    .set({ read: true }, { merge: true });
};

export const markNotificationsAsRead = (notificationIDs) => {
  const firestore = firebase.firestore();

  const batch = firestore.batch();

  notificationIDs.forEach((notificationID) => {
    const docRef = firestore.collection('notifications').doc(notificationID); // get new ID
    batch.set(docRef, { read: true }, { merge: true });
  });

  return batch.commit().then();
};
