import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import ApproveRequestsForm from './ApproveRequestsForm';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function ApproveRequestsDialog({
  isOrganizer,
  handleClose,
  open,
  joinRequests,
  increaseParticipantCount,
  decreaseParticipantCount,
  handleDetailsClick,
                                 setParticipants,
  participants
}) {
  const { t } = useTranslation();
  //TODO: add organiser in participant for users
  return (
    <Dialog maxWidth="xs" open={open} fullWidth={true} onClose={handleClose}>
      {joinRequests.length === 0 && isOrganizer && (
        <Box p={2} mx="auto" mt="auto">
          <Typography component="span" variant="body2" color="textPrimary">
            {t('approval.no_requests')}
          </Typography>
        </Box>
      )}
      {joinRequests.length > 0 && (
        <ApproveRequestsForm
          participants={participants}
          isOrganizer={isOrganizer}
          handleClose={handleClose}
          joinRequests={joinRequests}
          increaseParticipantCount={increaseParticipantCount}
          decreaseParticipantCount={decreaseParticipantCount}
          handleDetailsClick={handleDetailsClick}
          setParticipants={setParticipants}
        />
      )}
    </Dialog>
  );
}

export default ApproveRequestsDialog;
