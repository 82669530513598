import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useForm } from 'react-hook-form';
import {
  signInWithEmail,
  signInWithFacebook,
  signInWithGoogle,
} from '../../common/firebaseService';
import { emailRegex } from '../../common/regexService';
import FacebookIcon from '@material-ui/icons/Facebook';
import google from '../layout/google_logo.svg';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  facebookBtn: {
    backgroundColor: '#3b5998',
    color: 'white',
  },
  googleBtn: {
    backgroundColor: 'white',
  },
}));

function SignInForm(props) {
  const { handleClose } = props;
  const classes = useStyles();
  const { register, handleSubmit, errors, setError, formState } = useForm();
  const { t } = useTranslation();
  const signIn = async (formValues) => {
    try {
      await signInWithEmail(formValues);
      handleClose();
    } catch (firebaseError) {
      if (firebaseError.code === 'auth/user-not-found') {
        firebaseError.message = t('authentication.account_not_found');
        setError('email', firebaseError);
      } else {
        firebaseError.message = t('authentication.incorrect_combination');
        setError('email', firebaseError);
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} onSubmit={handleSubmit(signIn)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                className={classes.facebookBtn}
                fullWidth={true}
                startIcon={<FacebookIcon />}
                onClick={() => signInWithFacebook()}
              >
                {t('authentication.fb_log_in_btn')}
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                className={classes.googleBtn}
                fullWidth
                variant="outlined"
                startIcon={
                  <img src={google} alt="google-logo" width={16} height={16} />
                }
                onClick={() => signInWithGoogle()}
              >
                <span style={{color: 'black'}}>{t('authentication.google_log_in_btn')}</span>
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Box p={2}>
                <Divider variant="middle" />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label={t('authentication.email_address')}
                name="email"
                autoComplete="email"
                inputRef={register({ required: true, pattern: emailRegex })}
                error={!!errors.email}
                helperText={errors.email && errors.email.message}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label={t('authentication.password')}
                type="password"
                id="password"
                autoComplete="current-password"
                inputRef={register({ required: true })}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={formState.isSubmitting}
          >
            {t('authentication.log_in')}
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default SignInForm;
