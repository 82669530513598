export const translatedRoutes = {
  en: {
    findActivities: '/',
    activityDetail: '/activity/:id',
    activityDetailWithoutId: '/activity',
    recurringActivityDetail: '/recurring-activity/:id',
    recurringActivityDetailWithoutId: '/recurring-activity',
    myActivities: '/activities',
    organizers: '/organizers',
    myProfile: '/profile',
    inbox: '/messages',
    beta: '/beta',
  },
  fr: {
    findActivities: '/',
    activityDetail: '/activite/:id',
    activityDetailWithoutId: '/activite',
    recurringActivityDetail: '/activite-recurrente/:id',
    recurringActivityDetailWithoutId: '/activite-recurrente',
    myActivities: '/activites',
    organizers: '/organisateurs',
    myProfile: '/profil',
    inbox: '/messages',
    beta: '/beta',
  },
};

export const baseURL = 'https://onyv.ch';
